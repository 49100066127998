import {IlamBilgisiEntity} from "@/entity/IlamBilgisiEntity";
import {IlamTipi} from "@/enum/IlamTipi";
import {MahkemeListResponseProvider} from "@/services/MahkemeService";
import { MahkemeEntity } from '@/entity/MahkemeEntity';

export class IlamBilgisiServiceListResponse {
    data!: IlamBilgisiEntity[];
}

export function IlamBilgisiListResponseProvider(depth:number=3): IlamBilgisiServiceListResponse {
    let mahkemelerResponse = Array<MahkemeEntity>();
    if(--depth > 0)
    {
        mahkemelerResponse = MahkemeListResponseProvider(depth).data;
    }
    return {
        data: [
            {
                ilamTipi:IlamTipi.diger,
                diger:"İzmir Bilmem Kaçıncı Noter",
                tuketiciHakemHeyeti:"",
                ilamEsasNo:2007853,
                ilamKararNo:2456456,
                ilamTarihi: new Date("2019-01-28 17:53"),
                ilamOzeti: "İLAM ÖZETİ BİLGİSİ",
                mahkeme: null
            },
            {
                ilamTipi:IlamTipi.hakemkarari,
                diger:null,
                tuketiciHakemHeyeti:"Tuketici Halem Heyeti Bornova Şubesi",
                ilamEsasNo:2007853,
                ilamKararNo:2456456,
                ilamTarihi: new Date("2017-01-20 17:53"),
                ilamOzeti: "İLAM ÖZETİ BİLGİSİ",
                mahkeme: null
            },

            {
                ilamTipi:IlamTipi.mahkeme,
                diger:null,
                tuketiciHakemHeyeti:null,
                ilamEsasNo:3212546,
                ilamKararNo:1112885,
                ilamTarihi: new Date("2018-05-18 17:53"),
                ilamOzeti: "İLAM ÖZETİ BİLGİSİ",
                mahkeme: mahkemelerResponse[1]
            },



        ]
    }
        ;
}

