export enum TebligatDurumu
{
    gonderildi="gonderildi",
    tebligedildi="tebligedildi",
    bilaoldu="bilaoldu",

}
export enum TebligatDurumuLabel
{
    gonderildi="Gönderildi",
    tebligedildi="Tebliğ Edildi",
    bilaoldu="Bila Oldu",

}