import {TebligatAlanEntity} from "@/entity/TebligatAlanEntity";

class TebligatAlanListResponse {
    data!: TebligatAlanEntity[]
}

export function TebligatAlanListResponseProvider(depth:number=3): TebligatAlanListResponse {

    return {
        data: [
            {
                id:1,
                alan:"Kendisine",
                isimGirilecek:true
            },
            {
                id:2,
                alan:"Aynı Konuttaki Yakını",
                isimGirilecek:true
            },            {
                id:3,
                alan:"Muhtar",
                isimGirilecek:false
            },            {
                id:4,
                alan:"Çalışanı",
                isimGirilecek:true
            },            {
                id:5,
                alan:"Karakol",
                isimGirilecek:false
            },

        ]
    }
}
