import {YedieminEntity} from "@/entity/Haciz/YedieminEntity";
import {AdresEntity} from "@/entity/AdresEntity";
import {AdresListResponseProvider} from "@/services/AdresService";


export class YedieminResponse{
    data!:YedieminEntity[];
}

export function YedieminListResponseProvider(depth:number=3):YedieminResponse{
    let adreslerResponse = Array<AdresEntity>();
    if(--depth > 0)
    {
        adreslerResponse = AdresListResponseProvider(depth).data;
    }
    return {
        data:[
            {
                mersisNo: "13213232",
                sirketUnvan: "BİM LTD ŞTİ",
                adres: adreslerResponse[0],
                yedieminUcret : 360,
            },
            {
                mersisNo: "13213232",
                sirketUnvan: "a101 LTD ŞTİ",
                adres: adreslerResponse[1],
                yedieminUcret : 360,
            },

        ]
    }
}


