




























































import {Component, Prop, Vue} from "vue-property-decorator";
import GenelDuzenle from "@/components/vekalet/muvekkil/GenelDuzenle.vue";
import {KisiKurumTuru} from "@/enum/KisiKurumTuru";
import {CinsiyetLabel} from "@/enum/Cinsiyet";
import {KisiEntity} from "@/entity/KisiEntity";
import {KurumEntity} from "@/entity/KurumEntity";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import KisiKurumForm from "@/components/kisi/kisiKurumForm.vue";


@Component({
    components: {KisiKurumForm, FormDialogButton, GenelDuzenle}
})
export default class MuvekkilGenel extends Vue {
    get sahisMi(): boolean {
        return this.item.kisiKurumTuru == KisiKurumTuru.sahis;
    }

    @Prop()
    item!: KisiEntity|KurumEntity;

    cinsiyetler = CinsiyetLabel;

    mounted() {

        window.setTimeout(() => {
            let index: number = parseInt(this.$route.params.id) - 1;
          //  this.item = MuvekkilListResponseProvider().data[index];
            console.log("Muvekkil genel", this.item);
        }, 200);


    }

    dialog = false

    close() {
        this.dialog = false;
    }
}
