
















import {Component, Prop, Vue} from "vue-property-decorator";
import {KimlikBelgesi} from "@/entity/KimlikBelgesiEntity";
import EhliyetViewCard from "@/components/kisi/kimlik/EhliyetViewCard.vue";
import AvukatKimlikKartiViewCard from "@/components/kisi/kimlik/AvukatKimlikKartiViewCard.vue";
import NufusCuzdaniViewCard from "@/components/kisi/kimlik/NufusCuzdaniViewCard.vue";
import PasaportViewCard from "@/components/kisi/kimlik/PasaportViewCard.vue";
import {KimlikBelgesiTuru} from "@/enum/KimlikBelgesiTuru";
import {KisiEntity} from "@/entity/KisiEntity";

@Component({
    components: {PasaportViewCard, NufusCuzdaniViewCard, AvukatKimlikKartiViewCard, EhliyetViewCard}
})
export default class KimlikKartiViewCard extends Vue {

    @Prop({required: true})

    item!: KimlikBelgesi;


    kimlikBelgesiTuru = KimlikBelgesiTuru;

    mounted() {
        console.log("viewe bastıgında item : ", this.item);
        console.log("viewe bastıgında kisi : ", this.kisi);
    }


    @Prop({})
    kisi!: KisiEntity;

}
