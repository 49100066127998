












import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import {AdresTuru, AdresTuruLabel} from "@/enum/AdresTuru";
import EnumPicker from "@/components/common-inputs/EnumPicker.vue";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {Cinsiyet, CinsiyetLabel} from "../../enum/Cinsiyet";

@Component({
    components: {EnumPicker}
})
export default class CinsiyetPicker extends Mixins(SimpleInputMixin) {
    turler = Cinsiyet;
    turlerLabel = CinsiyetLabel;
}
