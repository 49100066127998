export enum GonderilenTuru {
    arastirma = "arastirma",
    takiptalebi = "takiptalebi",
    diger = "diger",
}

export enum GonderilenTuruLabel {
    arastirma = "Araştırma",
    takiptalebi = "Takip Talebi",
    diger = "diger",
}
