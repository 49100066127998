import {IlceEntity} from "@/entity/IlceEntity";
import {IcraDairesiEntity} from "@/entity/IcraDairesiEntity";

export class IlEntity {
    id: number | null = null;
    plakaKodu: number | null = null;
    siraNo: number | null = null;
    isim: string | null = null;
    ilceler: Array<IlceEntity> = [];
    icraDaireleri: Array<IcraDairesiEntity> = [];
}
