import {BorcluEntity} from "@/entity/BorcluEntity";
import {KisiListResponseProvider} from "@/services/KisiService";
import {KurumListResponseProvider} from "@/services/KurumService";
import { TakipListResponseProvider } from './TakipService';
import { TahsilatListResponseProvider } from './TahsilatService';
import { TebligatListResponseProvider } from './TebligatService';
import { TebligatEntity } from '@/entity/TebligatEntity';
import { TahsilatEntity } from '@/entity/TahsilatEntity';
import { KisiEntity } from '@/entity/KisiEntity';
import { KurumEntity } from '@/entity/KurumEntity';
import { TakipEntity } from '@/entity/TakipEntity';
import {HacizEntity} from "@/entity/Haciz/HacizTemelEntity";
import {HacizListResponseProvider} from "@/services/Haciz/HacizListResponse";
import {TarafSifatiListResponseProvider} from "@/services/TarafSifatiService";
import {TarafSifatiEntity} from "@/entity/TarafSifatiEntity";

export interface BorcluListService {
    data: Array<BorcluEntity>;
}



export function BorcluListResponseProvider(depth:number=7): BorcluListService {
    let tahsilatlarResponse = Array<TahsilatEntity>();
    let kisiResponse = Array<KisiEntity>();
    let kurumResponse = Array<KurumEntity>();
    let takipResponse = Array<TakipEntity>();
    let tebligatlarArrayResponse = Array<Array<TebligatEntity>>();
    let hacizResponse = Array<HacizEntity>();
    let tarafSifatiResponse = Array<TarafSifatiEntity>();
    if(--depth > 0)
    {
        tahsilatlarResponse = TahsilatListResponseProvider(depth).data;
        kisiResponse = KisiListResponseProvider(depth).data;
        kurumResponse = KurumListResponseProvider(depth).data;
        takipResponse = TakipListResponseProvider(depth).data;
        hacizResponse = HacizListResponseProvider(depth).data;
        tarafSifatiResponse = TarafSifatiListResponseProvider(depth).data;
        tebligatlarArrayResponse = [
            TebligatListResponseProvider(depth,1).data,
            TebligatListResponseProvider(depth,2).data
        ];
    }
    return {
        data: [
            {
                id: 1,
                kisiKurum: kisiResponse[0],
                takip: takipResponse[0],
                tahsilatlar: tahsilatlarResponse,
                tebligatlar: tebligatlarArrayResponse[0],
                sorumlulukSiniriVarMi: true,
                sorumlulukSiniri: 2000,
                hacizler:hacizResponse,
                tarafSifati: tarafSifatiResponse[1],
            },
            {
                id: 2,
                kisiKurum: kurumResponse[0],
                takip: takipResponse[0],
                tahsilatlar: [],
                tebligatlar: [],
                sorumlulukSiniriVarMi: false,
                sorumlulukSiniri: null,
                hacizler:[],
                tarafSifati: tarafSifatiResponse[1],
            },
            {
                id: 3,
                takip: takipResponse[0],
                tahsilatlar: [],
                kisiKurum: kurumResponse[2],
                tebligatlar: tebligatlarArrayResponse[1],
                sorumlulukSiniriVarMi: false,
                sorumlulukSiniri: null,
                hacizler:[],
                tarafSifati: tarafSifatiResponse[1],
            },

        ]
    }
}



