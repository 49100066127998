import {TakipAltTipAyar} from "@/entity/TakipAltTipAyar";
import {HesapSekli} from "@/enum/HesapSekli";
import {HesapDonemi} from "@/enum/HesapDonemi";

export class TakipAltTipAyarlarService {
    data!: Array<TakipAltTipAyar>;
}

export function TakipAltTipAyarlarResponseProvider(depth:number=3): TakipAltTipAyarlarService {
    return {
        data: [
            {
                id:1,
                alanAdi:"Asıl Alacak",
                tercihEdilenAd:null,
                oranlananAlan:null,
                oran:null,
                sabitTutar:null,
                takipSonrasiFaizIslesinMi:null,
                alanTipi: 1,
                hesapSekli: HesapSekli.aylik,
                hesapDonemi: HesapDonemi.aylik,
            },
            {
                id:2,
                alanAdi:"Takip Öncesi Faiz",
                tercihEdilenAd:null,
                oranlananAlan:null,
                oran:null,
                sabitTutar:null,
                takipSonrasiFaizIslesinMi:null,
                alanTipi: 2,
                hesapDonemi: null,
                hesapSekli: null
            },
            {
                id:3,
                alanAdi:"Tazminat",
                tercihEdilenAd:"GDZ Tazminat",
                oranlananAlan:null,
                oran:null,
                sabitTutar:null,
                takipSonrasiFaizIslesinMi:null,
                alanTipi: 4,
                hesapDonemi: null,
                hesapSekli: null
            },
            {
                id:4,
                alanAdi:"Komisyon",
                tercihEdilenAd:null,
                oranlananAlan:null,
                oran:null,
                sabitTutar:null,
                takipSonrasiFaizIslesinMi:null,
                alanTipi: 4,
                hesapDonemi: null,
                hesapSekli: null
            },
            {
                id:5,
                alanAdi:"Protesto",
                tercihEdilenAd:null,
                oranlananAlan:null,
                oran:null,
                sabitTutar:null,
                takipSonrasiFaizIslesinMi:null,
                alanTipi: 1,
                hesapDonemi: null,
                hesapSekli: null
            },
            {
                id:6,
                alanAdi:"İhtar Masrafı",
                tercihEdilenAd:null,
                oranlananAlan:null,
                oran:null,
                sabitTutar:null,
                takipSonrasiFaizIslesinMi:null,
                alanTipi: 1,
                hesapDonemi: null,
                hesapSekli: null
            },
            {
                id:7,
                alanAdi:"BSMV 1",
                tercihEdilenAd:null,
                oranlananAlan:null,
                oran:null,
                sabitTutar:null,
                takipSonrasiFaizIslesinMi:null,
                alanTipi: 7,
                hesapDonemi: null,
                hesapSekli: null
            },
            {
                id:8,
                alanAdi:"İhtiyati Haciz Masrafı + Vekalet Ücreti",
                tercihEdilenAd:null,
                oranlananAlan:null,
                oran:null,
                sabitTutar:null,
                takipSonrasiFaizIslesinMi:null,
                alanTipi: 6,
                hesapDonemi: null,
                hesapSekli: null
            },
            {
                id:9,
                alanAdi:"Fon",
                tercihEdilenAd:null,
                oranlananAlan:null,
                oran:null,
                sabitTutar:null,
                takipSonrasiFaizIslesinMi:null,
                alanTipi: 3,
                hesapDonemi: null,
                hesapSekli: null
            },
            {
                id:10,
                alanAdi:"Ek Alan - 1",
                tercihEdilenAd:"GDZ Özel Gider",
                oranlananAlan:null,
                oran:10,
                sabitTutar:null,
                takipSonrasiFaizIslesinMi:false,
                alanTipi: 7,
                hesapDonemi: null,
                hesapSekli: null
            },
            {
                id:11,
                alanAdi:"Ek Alan - 2",
                tercihEdilenAd:null,
                oranlananAlan:null,
                oran:null,
                sabitTutar:null,
                takipSonrasiFaizIslesinMi:null,
                alanTipi: 7,
                hesapDonemi: null,
                hesapSekli: null
            },
            {
                id:12,
                alanAdi:"Ek Alan - 3",
                tercihEdilenAd:null,
                oranlananAlan:null,
                oran:null,
                sabitTutar:null,
                takipSonrasiFaizIslesinMi:null,
                alanTipi: 7,
                hesapDonemi: null,
                hesapSekli: null
            },
            {
                id:13,
                alanAdi:"Ek Alan-4",
                tercihEdilenAd:null,
                oranlananAlan:null,
                oran:null,
                sabitTutar:null,
                takipSonrasiFaizIslesinMi:null,
                alanTipi: 7,
                hesapDonemi: null,
                hesapSekli: null
            },
            {
                id:14,
                alanAdi:"Cezai Şart",
                tercihEdilenAd:null,
                oranlananAlan:null,
                oran:null,
                sabitTutar:null,
                takipSonrasiFaizIslesinMi:null,
                alanTipi: 5,
                hesapDonemi: null,
                hesapSekli: null
            },
            {
                id:15,
                alanAdi:"KDV",
                tercihEdilenAd:null,
                oranlananAlan:null,
                oran:null,
                sabitTutar:null,
                takipSonrasiFaizIslesinMi:null,
                alanTipi: 3,
                hesapDonemi: null,
                hesapSekli: null
            },
            {
                id:16,
                alanAdi:"Takip Toplamı",
                tercihEdilenAd:null,
                oranlananAlan:null,
                oran:null,
                sabitTutar:null,
                takipSonrasiFaizIslesinMi:null,
                alanTipi: 1,
                hesapDonemi: null,
                hesapSekli: null
            },
            {
                id:17,
                alanAdi:"Vekalet Ücreti",
                tercihEdilenAd:null,
                oranlananAlan:null,
                oran:null,
                sabitTutar:null,
                takipSonrasiFaizIslesinMi:null,
                alanTipi: 1,
                hesapDonemi: null,
                hesapSekli: null
            },
            {
                id:18,
                alanAdi:"Takip Sonrası Faiz",
                tercihEdilenAd:null,
                oranlananAlan:null,
                oran:null,
                sabitTutar:null,
                takipSonrasiFaizIslesinMi:null,
                alanTipi: 1,
                hesapDonemi: null,
                hesapSekli: null
            },
            {
                id:19,
                alanAdi:"BSMV - 2",
                tercihEdilenAd:null,
                oranlananAlan:null,
                oran:null,
                sabitTutar:null,
                takipSonrasiFaizIslesinMi:null,
                alanTipi: 3,
                hesapDonemi: null,
                hesapSekli: null
            },
            {
                id:20,
                alanAdi:"Fon - 2",
                tercihEdilenAd:null,
                oranlananAlan:null,
                oran:null,
                sabitTutar:null,
                takipSonrasiFaizIslesinMi:null,
                alanTipi: 3,
                hesapDonemi: null,
                hesapSekli: null
            },
            {
                id:21,
                alanAdi:"Harç",
                tercihEdilenAd:null,
                oranlananAlan:null,
                oran:null,
                sabitTutar:null,
                takipSonrasiFaizIslesinMi:null,
                alanTipi: 1,
                hesapDonemi: null,
                hesapSekli: null
            },
            {
                id:22,
                alanAdi:"Takip Sonrası Masraf",
                tercihEdilenAd:null,
                oranlananAlan:null,
                oran:null,
                sabitTutar:null,
                takipSonrasiFaizIslesinMi:null,
                alanTipi: 1,
                hesapDonemi: null,
                hesapSekli: null
            },
        ]
    }
}