

















































import {Component, Prop, Vue} from "vue-property-decorator";
import DeleteButton from "@/components/common-inputs/DeleteButton.vue";
import BankaBilgileriForm from "@/components/comps/forms/BankaBilgileriForm.vue";
import {HesapNumarasiEntity} from "@/entity/HesapNumarasiEntity";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";

@Component({
    components: {FormDialogButton, BankaBilgileriForm, DeleteButton}
})
export default class HesapNoBilgiList extends Vue {
    dialog = false;
    @Prop({})
    editable!: boolean;
    @Prop({default: true})
    addable!: boolean;

    @Prop({})
    items!: HesapNumarasiEntity[];

    save() {
        this.dialog = false;
    }


}
