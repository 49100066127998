










































import {Component, Prop, Vue} from "vue-property-decorator";
import {IletisimBilgisiEntity} from "../../../entity/IletisimBilgisiEntity";
import DeleteButton from "@/components/common-inputs/DeleteButton.vue";
import IletisimForm from "@/components/comps/forms/IletisimForm.vue";
import IletisimListLine from "@/components/comps/lists/IletisimListLine.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import {IletisimBilgisiTuruLabel} from "@/enum/IletisimBilgisiTuru";

@Component({
    components: {FormDialogButton, IletisimListLine, IletisimForm, DeleteButton}
})
export default class IletisimBilgisiList extends Vue {

    @Prop({})
    editable!: boolean;
    dialog = false;

    item={};

    iletisimBilgisiTurLabels = IletisimBilgisiTuruLabel;

    @Prop({})
    items!: IletisimBilgisiEntity[];

    save(item: IletisimBilgisiEntity) {
        //save to service
        this.dialog = false;
    }

/*          KULLANILMAYAN FONKSİYONLAR  - KAAN 10.06.2020
add() {
        this.editingItem = null;
        this.dialog = true;
    }

    edit(val: IletisimBilgisiEntity) {
        this.editingItem = Object.assign({}, val);

        this.dialog = true;
    } */
}
