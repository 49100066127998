export enum IlamTuru {
    isciAlacagi = "isciAlacagi",
    genel = "genel",
    birIsinYapilmasiyadaYapilmamasi = "birIsinYapilmasiyadaYapilmamasi",
    irtifakHakki = "irtifakHakki",
    doviz = "doviz",
    nafaka = "nafaka",
    limitliAlacak = "limitliAlacak"
}

export enum IlamTuruLabel {
    genel = "Genel",
    birIsinYapilmasiyadaYapilmamasi = "Bir İşin Yapılması ya da Yapılmaması",
    irtifakHakki = "İrtifak Hakkının ya da Gemi Üzerindeki İntifa Hakkının Kaldırılması",
    isciAlacagi = "İşci Alacağı",
    limitliAlacak = "Limitli Alacak",
    nafaka = "Nafaka",
    doviz = "Döviz"
}