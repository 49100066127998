import {HarcKalemiEntity} from "@/entity/HarcKalemiEntity";

export interface HarcResponse {
    data: Array<HarcKalemiEntity>
}

export function HarcResponseProvider(depth:number=3): HarcResponse {
    return {
        data: [
            {
                id:1,
                basvuruHarci:25,
                idareHarci:2,
                pesinHarcOrani:0.2,
                vekaletHarci:27,
                tahsilHarciOrani:4.55,
            },
            {
                id:2,
                basvuruHarci:23.40,
                idareHarci:3,
                pesinHarcOrani:2.52,
                vekaletHarci:17.80,
                tahsilHarciOrani:4.55,
            }
        ]
    }
}