















import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import {mask} from "vue-the-mask";
import {mixins} from "vue-class-component";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
    directives: {
        mask
    },
})
export default class TcKimlikNumarasi extends Mixins(SimpleInputMixin) {

    mask = "###########";
    tcKimlikNo = this.value;
    rules = [
        (value: any) => {
            return (value!=null && !!value) || "Zorunlu."
        },
        (value: any) => {
            return !!value || "Zorunlu."
        },
        (value: any) => {
            return (value && value.length == 11) || "11 karakter olmalıdır.";
        },
        (value: any) => {
            return (value!=null && this.$helper.tcKimlikNoValidate(value) == true) || "Tc Kimlik No doğru değil."
        },
    ];

    validate() {
        this.$emit("value", this.localValue);
    }
};
