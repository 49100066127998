
























































import {Component, Prop, Vue} from "vue-property-decorator";
import {AvukatPersonelEntity} from "@/entity/AvukatPersonelEntity";
import {DigerPersonelEntity} from "@/entity/DigerPersonelEntity";
import {PersonelTuruLabel} from "@/enum/PersonelTuru";
import PersonelBilgiForm from "@/components/buro/personel/PersonelBilgiForm.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";

@Component({
    components: {FormDialogButton, PersonelBilgiForm}
})
export default class PersonelBilgiList extends Vue {

    personelTuruLabels = PersonelTuruLabel
    @Prop()
    item!: AvukatPersonelEntity | DigerPersonelEntity;

    mounted() {
        console.log("Buro Detay, Servisten Gelen DATA seboo", this.item);
    }
    dialog = false

    close() {
        this.dialog = false;
    }
}
