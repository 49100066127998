import {TakipTipiEntity} from "@/entity/TakipTipiEntity";

export class TakipTipiListResponse {
    data!: TakipTipiEntity[];
}

export function TakipTipiListResponseProvider(depth:number=3): TakipTipiListResponse {
    return {
        data: [
            {
                id: 1,
                deger: "49",
                aciklama: "49 - İlamsız Takipler"
            },
            {
                id: 2,
                deger: "50",
                aciklama: "50 - Menkul Rehninin Paraya Çevrilmesi"
            },
            {
                id: 3,
                deger: "51",
                aciklama: "51 - Adi Kira ve Hasılat Kirası"
            },
            {
                id: 4,
                deger: "52",
                aciklama: "52 - Kambiyo Senetleriyle İflas Takibi"
            },
            {
                id: 5,
                deger: "53",
                aciklama: "53 - Para borcuna veya teminat verilmesine ilişkin ilam veya ilam niteliğindeki belgelere dayanan takipler"
            },
            {
                id: 6,
                deger: "54",
                aciklama: "54 - Menkul Teslimi veya GM Tahliye ve Teslimi"
            },
            {
                id: 7,
                deger: "55",
                aciklama: "55 - Çocuk Teslimi veya Çocukla Kişisel İlişki"
            },
            {
                id: 8,
                deger: "56",
                aciklama: "56 - Tahliye"
            },
            {
                id: 9,
                deger: "151",
                aciklama: "151 - İpoteğin Paraya Çevrilmesi (İcra Emri)"
            },
            {
                id: 10,
                deger: "152",
                aciklama: "152 - İpoteğin Paraya Çevrilmesi (Ödeme Emri)"
            },
            {
                id: 11,
                deger: "153",
                aciklama: "153 - İflas Yolu ile Adi Takip"
            },
            {
                id: 12,
                deger: "163",
                aciklama: "163 - Kambiyo Senetleriyle Haciz Takibi"
            },
            {
                id: 13,
                deger: "201",
                aciklama: "201 - Taşınır Rehnin Paraya Çevrilmesi (İcra Emri)"
            },
        ]
    }
}
