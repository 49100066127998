import {TakipAltTipiEntity} from "@/entity/TakipAltTipiEntity";
import {TakipTipiEntity} from "@/entity/TakipTipiEntity";
import {TakipTipiListResponseProvider} from "@/services/TakipTipiService";
import {TakipAltTipAyar} from "@/entity/TakipAltTipAyar";
import {TakipAltTipAyarlarResponseProvider} from "@/services/TakipAltTipAyarlarService";
import {HesapSekli} from "@/enum/HesapSekli";
import {FaizEntity} from "@/entity/FaizEntity";
import {FaizListResponseProvider} from "@/services/FaizService";
import {AlacakKalemiAlani} from "@/entity/AlacakKalemiAlani";
import {AlacakKalemAlanlariListResponseProvider} from "@/services/AlacakKalemAlanlariService";

export class TakipAltTipiListResponse {
    data!: Array<TakipAltTipiEntity>;
}

export function TakipAltTipiResponseProvider(depth: number = 3): TakipAltTipiListResponse {
    let takipTiplerResponse = Array<TakipTipiEntity>();
    let takipAyarlarResponse = Array<TakipAltTipAyar>()
    let faizlerResponse = Array<FaizEntity>();
    let tahsilatDusmeSiralariResponse = Array<AlacakKalemiAlani>();
    if (--depth > 0) {
        takipTiplerResponse = TakipTipiListResponseProvider(depth).data;
        faizlerResponse = FaizListResponseProvider(depth).data;
        takipAyarlarResponse = TakipAltTipAyarlarResponseProvider(depth).data;
        tahsilatDusmeSiralariResponse = AlacakKalemAlanlariListResponseProvider(depth).data;
    }
return {
    data: [
        {
            id: 1,
            deger: "Genel",
            takipTipi: takipTiplerResponse[0],
            takipAyarlari: takipAyarlarResponse,
            vekaletUcretleri: null,
            alacakli: null,
            TTMetni: "#TakipToplami Tutarındaki toplam alacağın icra giderleri, vek.ücr. ve takip tarihinden itibaren asıl alacağa işleyecek #FaizBilgisi faizi ile tahsili talebidir. (Fazlaya dair ve faiz oranlarındaki artıştan doğan talep hakkımız saklıdır) TBK. 100.mad. gereğince kısmi ödemeler öncelikle işlemiş faiz, masraf ve ferrilere mahsup edilecektir.",
            OEMetni: "#TakipToplami Tutarındaki toplam alacağın icra giderleri, vek.ücr. ve takip tarihinden itibaren asıl alacağa işleyecek #FaizBilgisi faizi ile tediyesidir. (Fazlaya dair ve faiz oranlarındaki artıştan doğan talep hakkımız saklıdır) TBK. 100.mad. gereğince kısmi ödemeler öncelikle işlemiş faiz, masraf ve ferrilere mahsup edilecektir.",
            FaizAciklamaMetni: null,
            takipOncesiFaiz: faizlerResponse[0],
            takipOncesiHesapSekli: HesapSekli.aylik,
            takipSonrasiFaiz: faizlerResponse[0],
            takipSonrasiHesapSekli: HesapSekli.aylik,
            tahsilatDusmeSirasi: tahsilatDusmeSiralariResponse,
        },
        {
            id: 2,
            deger: "Genel",
            takipTipi: takipTiplerResponse[1],
            takipAyarlari: null,
            vekaletUcretleri: null,
            alacakli: null,
            TTMetni: null,
            OEMetni: null,
            FaizAciklamaMetni: null,
            takipOncesiFaiz: faizlerResponse[0],
            takipOncesiHesapSekli: HesapSekli.aylik,
            takipSonrasiFaiz: faizlerResponse[0],
            takipSonrasiHesapSekli: HesapSekli.aylik,
            tahsilatDusmeSirasi: tahsilatDusmeSiralariResponse,
        },
        {
            id: 3,
            deger: "Genel",
            takipTipi: takipTiplerResponse[2],
            takipAyarlari: null,
            vekaletUcretleri: null,
            alacakli: null,
            TTMetni: null,
            OEMetni: null,
            FaizAciklamaMetni: null,
            takipOncesiFaiz: faizlerResponse[0],
            takipOncesiHesapSekli: HesapSekli.aylik,
            takipSonrasiFaiz: faizlerResponse[0],
            takipSonrasiHesapSekli: HesapSekli.aylik,
            tahsilatDusmeSirasi: tahsilatDusmeSiralariResponse,
        },
        {
            id: 4,
            deger: "Genel",
            takipTipi: takipTiplerResponse[3],
            takipAyarlari: null,
            vekaletUcretleri: null,
            alacakli: null,
            TTMetni: null,
            OEMetni: null,
            FaizAciklamaMetni: null,
            takipOncesiFaiz: faizlerResponse[0],
            takipOncesiHesapSekli: HesapSekli.aylik,
            takipSonrasiFaiz: faizlerResponse[0],
            takipSonrasiHesapSekli: HesapSekli.aylik,
            tahsilatDusmeSirasi: tahsilatDusmeSiralariResponse,
        },
        {
            id: 5,
            deger: "Genel",
            takipTipi: takipTiplerResponse[4],
            takipAyarlari: null,
            vekaletUcretleri: null,
            alacakli: null,
            TTMetni: null,
            OEMetni: null,
            FaizAciklamaMetni: null,
            takipOncesiFaiz: faizlerResponse[0],
            takipOncesiHesapSekli: HesapSekli.aylik,
            takipSonrasiFaiz: faizlerResponse[0],
            takipSonrasiHesapSekli: HesapSekli.aylik,
            tahsilatDusmeSirasi: tahsilatDusmeSiralariResponse,
        },
        {
            id: 6,
            deger: "Genel",
            takipTipi: takipTiplerResponse[5],
            takipAyarlari: null,
            vekaletUcretleri: null,
            alacakli: null,
            TTMetni: null,
            OEMetni: null,
            FaizAciklamaMetni: null,
            takipOncesiFaiz: faizlerResponse[0],
            takipOncesiHesapSekli: HesapSekli.aylik,
            takipSonrasiFaiz: faizlerResponse[0],
            takipSonrasiHesapSekli: HesapSekli.aylik,
            tahsilatDusmeSirasi: tahsilatDusmeSiralariResponse,
        },
        {
            id: 7,
            deger: "Genel",
            takipTipi: takipTiplerResponse[6],
            takipAyarlari: null,
            vekaletUcretleri: null,
            alacakli: null,
            TTMetni: null,
            OEMetni: null,
            FaizAciklamaMetni: null,
            takipOncesiFaiz: faizlerResponse[0],
            takipOncesiHesapSekli: HesapSekli.aylik,
            takipSonrasiFaiz: faizlerResponse[0],
            takipSonrasiHesapSekli: HesapSekli.aylik,
            tahsilatDusmeSirasi: tahsilatDusmeSiralariResponse,
        },
        {
            id: 8,
            deger: "Genel",
            takipTipi: takipTiplerResponse[7],
            takipAyarlari: null,
            vekaletUcretleri: null,
            alacakli: null,
            TTMetni: null,
            OEMetni: null,
            FaizAciklamaMetni: null,
            takipOncesiFaiz: faizlerResponse[0],
            takipOncesiHesapSekli: HesapSekli.aylik,
            takipSonrasiFaiz: faizlerResponse[0],
            takipSonrasiHesapSekli: HesapSekli.aylik,
            tahsilatDusmeSirasi: tahsilatDusmeSiralariResponse,
        },
        {
            id: 9,
            deger: "Genel",
            takipTipi: takipTiplerResponse[8],
            takipAyarlari: null,
            vekaletUcretleri: null,
            alacakli: null,
            TTMetni: null,
            OEMetni: null,
            FaizAciklamaMetni: null,
            takipOncesiFaiz: faizlerResponse[0],
            takipOncesiHesapSekli: HesapSekli.aylik,
            takipSonrasiFaiz: faizlerResponse[0],
            takipSonrasiHesapSekli: HesapSekli.aylik,
            tahsilatDusmeSirasi: tahsilatDusmeSiralariResponse,
        },
        {
            id: 10,
            deger: "Genel",
            takipTipi: takipTiplerResponse[9],
            takipAyarlari: null,
            vekaletUcretleri: null,
            alacakli: null,
            TTMetni: null,
            OEMetni: null,
            FaizAciklamaMetni: null,
            takipOncesiFaiz: faizlerResponse[0],
            takipOncesiHesapSekli: HesapSekli.aylik,
            takipSonrasiFaiz: faizlerResponse[0],
            takipSonrasiHesapSekli: HesapSekli.aylik,
            tahsilatDusmeSirasi: tahsilatDusmeSiralariResponse,
        },
        {
            id: 11,
            deger: "Genel",
            takipTipi: takipTiplerResponse[10],
            takipAyarlari: null,
            vekaletUcretleri: null,
            alacakli: null,
            TTMetni: null,
            OEMetni: null,
            FaizAciklamaMetni: null,
            takipOncesiFaiz: faizlerResponse[0],
            takipOncesiHesapSekli: HesapSekli.aylik,
            takipSonrasiFaiz: faizlerResponse[0],
            takipSonrasiHesapSekli: HesapSekli.aylik,
            tahsilatDusmeSirasi: tahsilatDusmeSiralariResponse,
        },
        {
            id: 12,
            deger: "Genel",
            takipTipi: takipTiplerResponse[11],
            takipAyarlari: null,
            vekaletUcretleri: null,
            alacakli: null,
            TTMetni: null,
            OEMetni: null,
            FaizAciklamaMetni: null,
            takipOncesiFaiz: faizlerResponse[0],
            takipOncesiHesapSekli: HesapSekli.aylik,
            takipSonrasiFaiz: faizlerResponse[0],
            takipSonrasiHesapSekli: HesapSekli.aylik,
            tahsilatDusmeSirasi: tahsilatDusmeSiralariResponse,
        },
        {
            id: 13,
            deger: "Genel",
            takipTipi: takipTiplerResponse[12],
            takipAyarlari: null,
            vekaletUcretleri: null,
            alacakli: null,
            TTMetni: null,
            OEMetni: null,
            FaizAciklamaMetni: null,
            takipOncesiFaiz: faizlerResponse[0],
            takipOncesiHesapSekli: HesapSekli.aylik,
            takipSonrasiFaiz: faizlerResponse[0],
            takipSonrasiHesapSekli: HesapSekli.aylik,
            tahsilatDusmeSirasi: tahsilatDusmeSiralariResponse,
        },
    ]
}
}