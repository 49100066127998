















import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import {CurrencyInputOptions, CurrencyOptions} from 'vue-currency-input';

@Component({})
export default class Tutar extends Vue {
    @Prop() label!: string;
    @Prop() paraBirimi?: ParaBirimiEntity;
    @Prop() value:any;
    localValue = this.value ? this.value : null;

     @Prop() color!: string;

     private localColor = this.color ? this.color : ''

    input(){
        this.$emit('input',this.localValue);
        return;
        /**
         * @todo Burayı fix etmek gererkli
         */
        //     let decimalTutar = this.$parseCurrency(this.localValue, this.paraBirimiLocale);
        //     if(decimalTutar == 0){
        //         this.$emit('input',null);
        //     }else{
        //         this.$emit('input',decimalTutar);
        //     }
    }

    get paraBirimiLocale():  CurrencyInputOptions{
        if(!this.paraBirimi){
            console.log("return from up");
            return <CurrencyInputOptions>{currency: <CurrencyOptions>{prefix:"", suffix:""}};
        }
        return <CurrencyInputOptions>{currency: <CurrencyOptions>{prefix:"", suffix:""}, locale: this.paraBirimi.localeCode};
    }

    get getLabel() :string {
        return this.label ? this.label : 'Tutar';
    }
}
