export interface selectable {
    text: string;
    value: string;
}

export function enum2select(enm: any): Array<selectable> {
    return Object.keys(enm).map(key => ({text: enm[key], value: key}))
}

export class SelectableListResponse {
    data!: selectable[];
}

