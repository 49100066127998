import { MahkemeTuruEntity } from '@/entity/MahkemeTuruEntity';

export class MahkemeTuruListResponse {
    data!: MahkemeTuruEntity[];
}

export function MahkemeTuruListResponseProvider(depth:number=3): MahkemeTuruListResponse {
    return {
        data: [
            {
                id: 1,
                name: "Hukuk Mahkemesi",
            },
            {
                id: 2,
                name: "Ceza Mahkemesi",
            }
        ]
    }
}
