import {CariHareketEntity} from "@/entity/CariHareketEntity";
import {CariHareketTurEntity} from "@/entity/CariHareketTurEntity";
import {CariHareketTurListResponseProvider} from "@/services/CariHareketTurService";

export class CariHareketListResponse{
    data!:CariHareketEntity[];
}

export function CariHareketListResponseProvider(depth:number=3):CariHareketListResponse{
    let turlerResponse = Array<CariHareketTurEntity>();
    if(--depth > 0)
    {
        turlerResponse = CariHareketTurListResponseProvider(depth).data;
    }
    return {
        data:[
            {
                id:1,
                tarih:new Date("2019-03-16"),
                makbuzNo:"213123",
                tur: turlerResponse[3],
                aciklama:"Takip Açılışı Avans",
                tutar:1000,
                artiMi:false,
            },
            {
                id:2,
                tarih:new Date("2020-01-30"),
                makbuzNo:"213123",
                tur: turlerResponse[5],
                aciklama:"Tahsil Edilen Paradan",
                tutar:2300,
                artiMi:true,
            },
            {
                id:3,
                tarih:new Date("2020-05-08"),
                makbuzNo:"213123",
                tur: turlerResponse[3],
                aciklama:"Masraflar",
                tutar:10000,
                artiMi:false,
            },
            {
                id:4,
                tarih:new Date("2020-05-08"),
                makbuzNo:"213123",
                tur: turlerResponse[3],
                aciklama:"Masraflar",
                tutar:1000,
                artiMi:false,
            },
            {
                id:5,
                tarih:new Date("2020-05-09"),
                makbuzNo:"213123",
                tur: turlerResponse[5],
                aciklama:"Tahsil Edilen Paradan",
                tutar:5300,
                artiMi:true,
            },
            {
                id:6,
                tarih:new Date("2020-05-10"),
                makbuzNo:"213123",
                tur: turlerResponse[5],
                aciklama:"Tahsil Edilen Paradan",
                tutar:12000,
                artiMi:true,
            },

        ]
    }
}


