import {NotTurEntity} from "@/entity/NotTurEntity";

export interface NotTurResponse {
    data: NotTurEntity[]
}


export function NotTurResponseProvider(depth:number=3): NotTurResponse {
    return {
        data: [
            {
                id: 1,
                notTur: 'Satış'
            },
            {
                id: 2,
                notTur: 'Görüşme'
            },
            {
                id: 3,
                notTur: 'Adliye Arama'
            },
            {
                id: 4,
                notTur: 'Haciz'
            },
            {
                id: 5,
                notTur: 'Rehin'
            },
            {
                id: 6,
                notTur: 'Özel Notlar'
            },
        ]
    }
}