import {TakipYoluEntity} from "@/entity/TakipYoluEntity";

export class TakipYoluListResponse {
    data!: TakipYoluEntity[];
}

export function TakipYoluResponseProvider(depth:number=3): TakipYoluListResponse {
    return {
        data: [
            {id: 1, deger: "Haciz", takipTipiId: 1},
            {id: 2, deger: "Haciz-Satış", takipTipiId: 1},
            {id: 3, deger: "Rehnin Paraya Çevrilmesi", takipTipiId: 2},
            {id: 4, deger: "Haciz ve Tahliye", takipTipiId: 3},
            {id: 5, deger: "İflas", takipTipiId: 4},
            {id: 6, deger: "İlamlı Takip", takipTipiId: 5},
            {id: 7, deger: "Haciz", takipTipiId: 5},
            {id: 8, deger: "Tahliye ve Kal", takipTipiId: 6},
            {id: 9, deger: "Çocuk Teslimi", takipTipiId: 7},
            {id: 10, deger: "Tahliye", takipTipiId: 8},
            {id: 11, deger: "İpoteğin Paraya Çevrilmesi", takipTipiId: 9},
            {id: 12, deger: "İpoteğin Paraya Çevrilmesi", takipTipiId: 10},
            {id: 13, deger: "İflas", takipTipiId: 11},
            {id: 14, deger: "Haciz", takipTipiId: 12},
            {id: 15, deger: "Rehnin Paraya Çevrilmesi", takipTipiId: 13}
        ]
    }
}