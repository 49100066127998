import {KimlikBelgesiEntity} from "@/entity/KimlikBelgesiEntity";
import {PasaportTuru} from "@/enum/PasaportTuru";
import {KimlikBelgesiTuru} from "@/enum/KimlikBelgesiTuru";


export class PasaportEntity extends KimlikBelgesiEntity {
    turu: PasaportTuru | null = null;
    ulkeKodu: number | null = null;
    pasaportNo: number | null = null;
    uyrugu: string | null = null;
    constructor() {
        super();
        this.kimlikBelgesiTuru = KimlikBelgesiTuru.pasaport;
    }
}


