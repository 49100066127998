import {MahkemeEntity} from "@/entity/MahkemeEntity";
import {IlListResponseProvider} from "@/services/IlService";
import { MahkemeDosyaTurListResponse, MahkemeDosyaTurListResponseProvider } from './MahkemeDosyaTurService';
import { MahkemeTuruListResponseProvider } from './MahkemeTuruService';
import { MahkemeTuruEntity } from '@/entity/MahkemeTuruEntity';
import { MahkemeDosyaTurEntity } from '@/entity/MahkemeDosyaTurEntity';
import { IlEntity } from '@/entity/IlEntity';

export class MahkemeListResponse {
    data!: MahkemeEntity[];
}

export function MahkemeListResponseProvider(depth:number=3): MahkemeListResponse {
    let mahkemeTurleriResponse = Array<MahkemeTuruEntity>();
    let mahkemeDosyaTurleriResponse = Array<MahkemeDosyaTurEntity>();
    let illerResponse = Array<IlEntity>();
    if(--depth > 0)
    {
        mahkemeTurleriResponse = MahkemeTuruListResponseProvider(depth).data;
        mahkemeDosyaTurleriResponse = MahkemeDosyaTurListResponseProvider(depth).data;
        illerResponse = IlListResponseProvider(depth).data;
    }
    return {
        data: [
            {
                id: 1,
                mahkemeTuru: mahkemeTurleriResponse[0],
                mahkemeDosyaTur: mahkemeDosyaTurleriResponse[0],
                birimId: 1011031,
                il: illerResponse[0],
                yargiBirimi: "0926",
                adliyeOrg: "1.04.071.000.4001",
                birimAdi: "İzmir 1.Aile Mahkemesi",
            },
            {
                id: 2,
                mahkemeTuru: mahkemeTurleriResponse[1],
                mahkemeDosyaTur: mahkemeDosyaTurleriResponse[0],
                birimId: 1054529,
                il: illerResponse[3],
                yargiBirimi: "0901",
                adliyeOrg: "1.04.034.000.4001",
                birimAdi: "Bursa Ağır Ceza Mahkemesi",
            }
        ]
    }
}
