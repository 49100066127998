



































































































import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import IptalButton from "@/components/inputs/IptalButton.vue";
import KaydetButton from "@/components/inputs/KaydetButton.vue";
import {KisiEntity} from "@/entity/KisiEntity";
import {KurumEntity} from "@/entity/KurumEntity";
import {KisiKurumTuru} from "@/enum/KisiKurumTuru";
import TcKimlikNumarasi from "@/components/inputs/TcKimlikNumarasi.vue";
import VergiNumarasi from "@/components/inputs/VergiNumarasi.vue";
import {Cinsiyet} from "@/enum/Cinsiyet";
import {KamuOzel} from "@/enum/KamuOzel";
import IlPicker from "@/components/pickers/IlPicker.vue";
import Dates from "@/components/inputs/Dates.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import AdresList from "@/components/comps/lists/AdresList.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import IletisimList from "@/components/comps/lists/IletisimList.vue";
import HesapNoList from "@/components/comps/lists/HesapNoList.vue";

const defaultKisiEntity = new KisiEntity();

@Component({
    components: {
        HesapNoList,
        IletisimList,
        FormDialogButton,
        AdresList,
        Dates,
        IlPicker,
        VergiNumarasi,
        TcKimlikNumarasi,
        KaydetButton,
        IptalButton
    },
})
export default class KisiKurumForm extends Mixins(ObjectInputMixin) {
    @Prop({})
    baslik!: string;

    @Prop({})
    detay!: boolean;


    @Prop({default: defaultKisiEntity})
    item: any;


    @Watch('value.kisiKurumTuru')
    oValueChanged(val: string, oldVal: string) {
        console.log("oValueChanged");
        console.log("val : ", val);
        console.log("oldVal : ", oldVal);

        switch (val) {
            case this.kisiKurumTuru.sahis:
                this.localValue = Object.assign({}, new KisiEntity(), this.localValue);
                break;
            case this.kisiKurumTuru.sirket:
                this.localValue = Object.assign({}, new KurumEntity(), this.localValue);
                break;


        }

    }

    cinsiyet = Cinsiyet;
    kamuOzel = KamuOzel;

    // @Prop()
    // item: KisiKurumEntity = new KisiEntity();

    /*  @Prop({})
      value!: KisiEntity | KurumEntity;*/
    kisiKurumTuru = KisiKurumTuru;
    dialogAdres: boolean = false;

    openAdreslerDialog() {
        this.dialogAdres = true;
    }

    get isSahis() {
        return this.localValue.kisiKurumTuru != KisiKurumTuru.sirket;
    }

    mounted() {

    }
};
