






















































import {Component, Prop, Vue} from "vue-property-decorator";
import DeleteButton from "@/components/common-inputs/DeleteButton.vue";
import TakipTalebiForm from "@/components/takip/form/TakipTalebiForm.vue";
import Kosullar from "@/components/takip/form/Kosullar.vue";
import FilterForm from "@/components/comps/forms/FilterForm.vue";
import {AxiosError, AxiosResponse} from "axios";
import NameFilter from "@/components/inputs/filter/NameFilter.vue";
import {TakipEntity} from "@/entity/TakipEntity";
import {TakipListResponseProvider} from "@/services/TakipService";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";

import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import KisaMetin from "@/components/comps/tools/KisaMetin.vue";


@Component({
    components: {
        KisaMetin,
        ItemArrayManager,
        FormDialogButton, TakipTalebiForm, FilterForm, DeleteButton, Kosullar
    }
})
export default class TakipList extends Vue {
    takipTalebiObject: TakipTalebiEntity = new TakipTalebiEntity();
    filter = false
    dialog = false;
    search: string = "";


    @Prop()
    items!: TakipEntity[];

    headers = [
        {
            text: "Kart No",
            align: "start",
            sortable: true,
            value: "takipTalebi.kartNo"
        },
        {text: "Takip Tipi", value: "takipTalebi.takipTipi.aciklama"},
        {text: "Takip Alt Tipi", value: "takipTalebi.takipAltTipi.deger"},
        {text: "Takip Yolu", value: "takipTalebi.takipYolu.deger"},
        {text: "Takip Durumu", value: "takipTalebi.takipDurumu"},
        {text: "Dosya Esas No", value: "takipTalebi.dosyaEsasNo"},
        {text: "İcra Dairesi", value: "takipTalebi.icraDairesi.isim"},
        {text: "Takip Tarihi", value: "takipTalebi.takipTarihi"},
        {text: "İşlemler", value: "actions", sortable: false}
    ];


    add() {
        this.$router.push('/buro/ekle');
    }


    editTakip(item: TakipEntity) {
        //   this.$router.push('/buro/' + item.id.toString() + '/duzenle');
    }

    deleteTakip(item: TakipEntity) {
        // emin misin!
    }

    detay(item: TakipEntity) {
        this.$store.state.location="anakart";
        if (item.id)
            this.$router.push('/takip/' + item.id.toString() + '/detay');
    }

    ara() {
        //this.$http.get('/api/takipler/filter',this.filterData).then( (response:AxiosResponse)=>{}).catch((err:AxiosError)=>{})
    }

}
