







import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {mixins} from "vue-class-component";@Component({})
export default class QueryPicker extends Mixins(ObjectInputMixin) {
    @Prop() label!:string;
    get items(){
        return [
            {text:"Halk Bankası", value:"3"},
            {text:"Vakıflar Bankası", value:"2"},
            {text:"Ziraat Bankası", value:"1"},
        ];
    }
}
