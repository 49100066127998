


















import {Component, Prop, Vue} from "vue-property-decorator";


@Component({})
export default class KisaMetin extends Vue {
    @Prop() length: any;
    @Prop() value: any;

    defaultLength = 25;

    get isCutRequired(): boolean {
        return (this.value.length > (this.getLength + 4));
    }

    get getDisplayValue(): string {
        let str = this.value.substr(0, this.getLength + 3);
        let lastSpacePos = str.lastIndexOf(' ');
        return str.substr(0, lastSpacePos) + '...';
    }

    get getValueWithNewLines(): string[] {
        let newArray = [];
        let arrayOfString = this.value.split(" ");
        console.log("array:", arrayOfString);
        let spaceCount = (arrayOfString.length - 1);
        if (spaceCount > 7) {
            let newLine = "";
            for (let i = 0; i < spaceCount; i++) {
                newLine += arrayOfString[i] + " ";
                console.log("newLine:", newLine);
                if (i != 0 && i % 5 == 0) {
                    newArray.push(newLine);
                    newLine = "";
                    console.log("newArray:", newArray);
                }
                if (i == spaceCount - 1 && newLine != "") {
                    newArray.push(newLine);
                    console.log("newArray:", newArray);
                }
            }
        } else {
            newArray.push(this.value);
        }
        return newArray;
    }

    get getStringValue(): string {
        if (typeof this.value == "string") {
            return this.value;
        }
        return "";
    }


    get getLength(): number {
        if (this.length) {
            try {
                return parseInt(this.length);
            } catch (e) {

            }
        }
        return this.defaultLength;
    }


}
