import {ParaBirimiListResponseProvider} from "@/services/ParaBirimiService";
import { ParaBirimiEntity } from '@/entity/ParaBirimiEntity';
import { NafakaEntity } from '@/entity/AlacakBelgeler/NafakaEntity';
import { IlamliDigerEntity } from '@/entity/AlacakBelgeler/IlamliDigerEntity';


class IlamliDigerListResponse {
    data!:IlamliDigerEntity[]
}

export function IlamliDigerListResponseProvider(depth:number=3): IlamliDigerListResponse {
    let paraBirimleriResponse = Array<ParaBirimiEntity>();
    if(--depth > 0)
    {
        paraBirimleriResponse = ParaBirimiListResponseProvider(depth).data;
    }
    return {
        data: [
            {
                id:1,
                baslangicTarihi: new Date("2010-02-12"),
                bitisTarihi: new Date("2012-02-12"),
                tutar:500,
                paraBirimi:paraBirimleriResponse[0]
            },
            {
                id:2,
                baslangicTarihi: new Date("2012-02-12"),
                bitisTarihi: new Date("2019-02-12"),
                tutar:1500,
                paraBirimi:paraBirimleriResponse[0]
            }
        ]
    }
}
