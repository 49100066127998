



















import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import IptalButton from "@/components/inputs/IptalButton.vue";
import KaydetButton from "@/components/inputs/KaydetButton.vue";
import IbanNumarasi from "@/components/inputs/IbanNumarasi.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import BankaPicker from "@/components/pickers/BankaPicker.vue";
import ParaBirimi from "@/components/inputs/ParaBirimi.vue";

@Component({
    components: {
        ParaBirimi,
        BankaPicker,
        IbanNumarasi,
        KaydetButton,
        IptalButton
    },
})
export default class BankaBilgileriForm extends Mixins(ObjectInputMixin) {

};
