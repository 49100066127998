export enum TahsilatTuru
{
    avukattahsilati="avukattahsilati",
    muvekkiltahsilati="muvekkiltahsilati",
    icrayatahsilat="icrayatahsilat",
    bantahsilat="bantahsilat",
    bayitahsilati="bayitahsilati",
    masraftahsilati="masraftahsilati",
    taahhutgresmi="taahhutgresmi",
    taahhutresmi="taahhutresmi",
    kredikarti="kredikarti",
}
export enum TahsilatTuruLabel
{
    avukattahsilati="Avukat Tahsilatı",
    muvekkiltahsilati="Müvekkil Tahsilatı",
    icrayatahsilat="İcraya Tahsilat",
    bantahsilat="Ban. Tahsilat",
    bayitahsilati="Bayi Tahsilatı",
    masraftahsilati="Masraf Tahsilatı",
    taahhutgresmi="Taahhüt Gayri Resmi",
    taahhutresmi="Taahhüt Resmi",
    kredikarti="Kredi Kartı",
}