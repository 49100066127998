

































import {IlListResponseProvider} from '@/services/IlService';
import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import {AdresEntity} from "@/entity/AdresEntity";
import {IlEntity} from "@/entity/IlEntity";
import {AdresTurListResponseProvider} from "@/services/AdresTuruSevice";
import {AdresTuru, AdresTuruLabel} from "@/enum/AdresTuru";
import AdresTuruPicker from "@/components/pickers/AdresTuruPicker.vue";
import IlPicker from "@/components/pickers/IlPicker.vue";
import IlcePicker from "@/components/pickers/IlcePicker.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component({
    components: {
        IlcePicker,
        IlPicker,
        AdresTuruPicker
    },
})
export default class AdresForm extends Mixins(ObjectInputMixin) {

    il: IlEntity =  this.value.ilce ? this.value.ilce.il : new IlEntity();

};
