import {NotEntity} from "@/entity/NotEntity";
import {Alanlar} from "@/enum/Alanlar";
import {NotTurResponseProvider} from "@/services/NotTurService";
import {TakipListResponseProvider} from "@/services/TakipService";
import { NotTurEntity } from '@/entity/NotTurEntity';
import { TakipEntity } from '@/entity/TakipEntity';

class NotListResponse {
    data!: NotEntity[]
}


export function NotListResponseProvider(depth:number=3): NotListResponse {
    let notTurleriResponse = Array<NotTurEntity>();
    let takiplerResponse = Array<TakipEntity>();
    if(--depth > 0)
    {
        notTurleriResponse = NotTurResponseProvider(depth).data;
        takiplerResponse = TakipListResponseProvider(depth).data;
    }
    return {
        data: [
            {
                id: 1,
                tarih: new Date("2020-01-01T12:01:21Z"),
                icerik: "Vekalet bölümüne eklenen bir not",
                tur: notTurleriResponse[0],
                ekleyen: "Yunus Özyavuz",
                kart: takiplerResponse[0],
                parent: null,
                gosterilecekAlan: Alanlar.vekalet,
            },
            {
                id: 2,
                tarih: new Date("2020-04-03T12:15:28Z"),
                icerik: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
                tur: notTurleriResponse[0],
                ekleyen: "Bilgin Özçalkan",
                kart: takiplerResponse[0],
                parent: {id:1},
                gosterilecekAlan: Alanlar.vekalet,
            },
            {
                id: 3,
                tarih: new Date("2020-05-15T12:36:28Z"),
                icerik: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ",
                tur: notTurleriResponse[0],
                ekleyen: "Yunus Özyavuz",
                kart: takiplerResponse[0],
                parent: {id:1},
                gosterilecekAlan: Alanlar.vekalet,
            }
        ]
    }
}

