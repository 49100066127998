import {KimlikBelgesiEntity} from "@/entity/KimlikBelgesiEntity";
import {IlceEntity} from "@/entity/IlceEntity";
import {KimlikBelgesiTuru} from "@/enum/KimlikBelgesiTuru";


export class NufusCuzdaniEntity extends KimlikBelgesiEntity {
    seri: string | null = null;
    seriNo: number | null = null;
    babaAdi: string | null = null;
    anneAdi: string | null = null;
    ilce: IlceEntity | null = null;
    mahalleKoy: string | null = null;
    ciltNo: number | null = null;
    aileSiraNo: number | null = null;
    siraNo: number | null = null;
    constructor() {
        super();
        this.kimlikBelgesiTuru = KimlikBelgesiTuru.nufusCuzdani;
    }
}
