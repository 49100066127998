import {AlacakliEntity} from "@/entity/AlacakliEntity";
import {MuvekkilEntity} from "@/entity/MuvekkilEntity";
import {MuvekkilListResponseProvider} from "@/services/MuvekkilService";
import { TakipListResponseProvider } from './TakipService';
import { TakipEntity } from '@/entity/TakipEntity';

export interface AlacakliListResponse {
    data: Array<AlacakliEntity>;
}

export function AlacakliListResponseProvider(depth:number=3): AlacakliListResponse {
    let takipResponse = Array<TakipEntity>();
    let muvekkilResponse = Array<MuvekkilEntity>();
    if(--depth > 0)
    {
        takipResponse = TakipListResponseProvider(depth).data;
        muvekkilResponse = MuvekkilListResponseProvider(depth).data;
    }
    return {
        data: [
            {
                id: 1,
                takip: takipResponse[0],
                muvekkil: muvekkilResponse[1],
                harcMuaf: false
            },
        ]
    }
}



