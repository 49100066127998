export enum TeminatTuru {
    nakit = "nakit",
    mektup = "mektup",
    diger = "diger",
}

export enum TeminatTuruLabel {
    nakit = "Nakit",
    mektup = "Mektup",
    diger = "Diğer",
}
