import {AdresEntity} from "@/entity/AdresEntity";
import {IletisimBilgisiEntity} from "@/entity/IletisimBilgisiEntity";
import {HesapNumarasiEntity} from "@/entity/HesapNumarasiEntity";
import {KisiKurumTuru} from "@/enum/KisiKurumTuru";
import {KisiEntity} from "@/entity/KisiEntity";
import {KurumEntity} from "@/entity/KurumEntity";

export type KisiKurum = KisiEntity | KurumEntity;

export abstract class KisiKurumEntity {
    id: number | null = null;
    adresler: Array<AdresEntity> = [];
    iletisimBilgileri: Array<IletisimBilgisiEntity> = [];
    hesaplar: Array<HesapNumarasiEntity> = [];
    kisiKurumTuru: KisiKurumTuru | null = null;
}
/*
kisiKurum
sebo : 1
kaan : 2
baho : 3
zirve: 4

vekalet
seboKaanVekaleti : 1
trioVekalet      : 2


kisiKurumVekalet
 mID vkId
   1    1
   2    1
   1    2
   2    2
   3    2




 */
