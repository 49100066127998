































































import {Component, Prop, Vue} from "vue-property-decorator";
import {NufusCuzdaniEntity} from "@/entity/NufusCuzdaniEntity";
import {KisiEntity} from "@/entity/KisiEntity";
import IptalButton from "@/components/inputs/IptalButton.vue";

@Component({
    components: {IptalButton}
})
export default class NufusCuzdaniViewCard extends Vue {
    @Prop({})
    nufusCuzdani!: NufusCuzdaniEntity;

    @Prop({})
    kisi!: KisiEntity;

    close() {
        this.$emit("close");
    }

    mounted(){
        console.log("Nüfus Cüzdanı: ", this.nufusCuzdani);
    }
}
