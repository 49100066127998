import {FaizOranGecmisEntity} from "@/entity/FaizOranGecmisEntity";
import { FaizListResponseProvider } from './FaizService';
import { FaizEntity } from '@/entity/FaizEntity';


class FaizOranListResponse {
    data!: FaizOranGecmisEntity[]
}

export function FaizOranListResponseProvider(depth:number=3): FaizOranListResponse {
    let faizlerResponse = Array<FaizEntity>();
    if(--depth > 0)
    {
        faizlerResponse = FaizListResponseProvider(depth).data;
    }
    return {
        data: [
            {
                oran: 27.00,
                faiz: faizlerResponse[0],
                baslangicTarihi: new Date("2008-01-01"),
                bitisTarihi: new Date("2008-12-31"),
            },
            {
                oran: 19.00,
                faiz: faizlerResponse[0],
                baslangicTarihi: new Date("2009-01-01"),
                bitisTarihi: new Date("2009-12-31"),
            },
            {
                oran: 16.00,
                faiz: faizlerResponse[0],
                baslangicTarihi: new Date("2010-01-01"),
                bitisTarihi: new Date("2010-12-31"),
            },
            {
                oran: 15.00,
                faiz: faizlerResponse[0],
                baslangicTarihi: new Date("2011-01-01"),
                bitisTarihi: new Date("2011-12-31"),
            },
            {
                oran: 17.75,
                faiz: faizlerResponse[0],
                baslangicTarihi: new Date("2012-01-01"),
                bitisTarihi: new Date("2012-12-31"),
            },
            {
                oran: 13.75,
                faiz: faizlerResponse[0],
                baslangicTarihi: new Date("2013-01-01"),
                bitisTarihi: new Date("2013-12-31"),
            },
            {
                oran: 11.75,
                faiz: faizlerResponse[0],
                baslangicTarihi: new Date("2014-01-01"),
                bitisTarihi: new Date("2014-12-31"),
            },
            {
                oran: 10.50,
                faiz: faizlerResponse[0],
                baslangicTarihi: new Date("2015-01-01"),
                bitisTarihi: new Date("2015-12-31"),
            },
            {
                oran: 9.75,
                faiz: faizlerResponse[0],
                baslangicTarihi: new Date("2017-01-01"),
                bitisTarihi: new Date("2017-12-31"),
            },
            {
                oran: 19.50,
                faiz: faizlerResponse[0],
                baslangicTarihi: new Date("2018-01-01"),
                bitisTarihi: new Date("2018-12-31"),
            },
            {
                oran: 13.75,
                faiz: faizlerResponse[0],
                baslangicTarihi: new Date("2020-01-01"),
                bitisTarihi: new Date("2020-12-31"),
            },

        ]
    }
}
