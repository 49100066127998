






































import {Component, Prop, Vue} from "vue-property-decorator";
import KaydetButton from "@/components/inputs/KaydetButton.vue";
import IptalButton from "@/components/inputs/IptalButton.vue";
import {IletisimBilgisiEntity} from "@/entity/IletisimBilgisiEntity";
import {IletisimBilgisiTurListResponseProvider} from "@/services/IletisimBilgisiTurService";
import {IletisimBilgisiTuru, IletisimBilgisiTuruLabel} from "@/enum/IletisimBilgisiTuru";
import EPosta from "@/components/inputs/EPosta.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import EnumPicker from "@/components/common-inputs/EnumPicker.vue";

@Component({
    components: {
        EnumPicker,
        EPosta,
        KaydetButton,
        IptalButton
    },
})
export default class IletisimForm extends ObjectInputMixin {
    turlerEnum = IletisimBilgisiTuru;
    turlerLabelEnum = IletisimBilgisiTuruLabel;

};
