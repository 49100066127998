
























import {Component, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {mixins} from "vue-class-component";
import Dates from "@/components/inputs/Dates.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import {CariHareketTurListResponseProvider} from "@/services/CariHareketTurService";


@Component({
    components: {
        Tutar,
        Dates,
    },
})
export default class CariHareketForm extends mixins(ObjectInputMixin) {
    Turler = CariHareketTurListResponseProvider().data;

    @Prop()
    artiMi!: boolean;

    Mounted() {
        console.log("form load: ",this.localValue);
        if (this.localValue.artiMi !== true && this.localValue.artiMi !== false) {
            console.log("form empty load: ",this.localValue);
            this.localValue.artiMi = this.artiMi;
            console.log("form empty added: ",this.localValue);
        }
    }
};
