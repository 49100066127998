




















































































import {Component, Prop, Vue} from "vue-property-decorator";
import AramaGecmisi from "@/components/inputs/filter/AramaGecmisi.vue";
import Filter from "@/components/inputs/filter/Filter.vue";
import {VTextField} from "vuetify/lib";
import NameFilter from "@/components/inputs/filter/NameFilter.vue";


@Component({
    components: {AramaGecmisi}
})
export default class FilterForm extends Vue {
    @Prop({})
    hedef!: string;

    @Prop({})
    filters!:Array<any>;


    l(){
        console.log(this);
    }

    get availableFilters(): Array<object> {
        console.log(this);
        return [];
    }

    addFilter() {

    }
}
