



























import {Component, Prop, Vue} from "vue-property-decorator";
import {IletisimBilgisiEntity} from "../../../entity/IletisimBilgisiEntity";
import {IletisimBilgisiTuruLabel} from "@/enum/IletisimBilgisiTuru";
import DeleteButton from "@/components/common-inputs/DeleteButton.vue";
import IletisimForm from "@/components/comps/forms/IletisimForm.vue";

@Component({
    components: {IletisimForm, DeleteButton}
})
export default class IletisimListLine extends Vue {

    iletisimBilgisiTurLabels = IletisimBilgisiTuruLabel

    @Prop({default: true})
    editable!: boolean;

    @Prop({})
    item!: IletisimBilgisiEntity;

    dialog: boolean = false;
}
