export enum VekilTipi {
    sahis = "sahis",
    buro = "buro",
    kamu = "kamu"
}

export enum VekilTipiLabel {
    sahis = "Şahıs",
    buro = "Büro",
    kamu = "Kamu"
}
