












import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import {AdresTuru, AdresTuruLabel} from "@/enum/AdresTuru";
import EnumPicker from "@/components/common-inputs/EnumPicker.vue";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {IlamTuru, IlamTuruLabel} from "../../enum/IlamTuru";

@Component({
    components: {EnumPicker}
})
export default class IlamTuruPicker extends Mixins(SimpleInputMixin) {
    turler = IlamTuru;
    turlerLabel = IlamTuruLabel;
}
