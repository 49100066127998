































































import {Component, Mixins} from "vue-property-decorator";
import {IlListResponseProvider} from "@/services/IlService";
import {IcraDairesiEntity} from "@/entity/IcraDairesiEntity";
import {TakipTipiListResponseProvider} from "@/services/TakipTipiService";
import {TakipAltTipiEntity} from "@/entity/TakipAltTipiEntity";
import {TakipYoluEntity} from "@/entity/TakipYoluEntity";
import {TakipAltTipiResponseProvider} from "@/services/TakipAltTipiService";
import {TakipYoluResponseProvider} from "@/services/TakipYoluService";
import Dates from "@/components/inputs/Dates.vue";
import DosyaEsasNumarasi from "@/components/inputs/DosyaEsasNumarasi.vue";
import IlPicker from "@/components/pickers/IlPicker.vue";
import IcraDairesiPicker from "@/components/pickers/IcraDairesiPicker.vue";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import IlamTuruPicker from "@/components/pickers/IlamTuruPicker.vue";

@Component({
    components: {IlamTuruPicker, IcraDairesiPicker, IlPicker, DosyaEsasNumarasi, Dates}
})
export default class TakipTalebiForm extends Mixins(ObjectInputMixin) {


    menu: boolean = false;
    illerState = IlListResponseProvider().data;
    icraDaireleriState: Array<IcraDairesiEntity> = [];
    takipTipleriState = TakipTipiListResponseProvider().data;
    takipAltTipleriState: Array<TakipAltTipiEntity> = [];
    takipYollariState: Array<TakipYoluEntity> = [];


    mounted() {
        console.log("shit: ", this.value);
        //console.log("shit new: ", this.val);

        this.loadTakipAltTipAndYol();
    }

    onTakipTipiChange() {
        console.log("onTakipTipiChange", this.localValue);
        this.loadTakipAltTipAndYol();
        this.$emit("value", this.localValue);
    }

    loadTakipAltTipAndYol() {
        this.takipAltTipleriState = [];
        this.takipYollariState = [];
        //console.log("gelen id: ", this.item.takipTipi.id);
        this.takipAltTipleriState = TakipAltTipiResponseProvider().data;
        this.takipYollariState = TakipYoluResponseProvider().data;
        // this.takipYollari =
    }


    get takipYollari(): Array<TakipYoluEntity> {
        if (!this.localValue.takipTipi) {
            return [];
        }
        return this.takipYollariState.filter(ttt => ttt.takipTipiId === this.localValue.takipTipi.id);
    }

    get takipAltTipleri(): Array<TakipAltTipiEntity> {
        if (!this.localValue.takipTipi) {
            return [];
        }
        return this.takipAltTipleriState.filter(ttt => ttt.takipTipi?.id === this.localValue.takipTipi.id);
    }

    get isIlamli() {
        return this.localValue.takipTipi.deger == '53' || this.localValue.takipTipi.deger == '54' || this.localValue.takipTipi.deger == "55" || this.localValue.takipTipi.deger == "151" || this.localValue.takipTipi.deger == "201";
    }
}
