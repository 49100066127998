






































import {Component, Prop, Vue} from "vue-property-decorator";
import {EhliyetEntity} from "../../../entity/EhliyetEntity";
import {KisiEntity} from "../../../entity/KisiEntity";
import IptalButton from "@/components/inputs/IptalButton.vue";

@Component({
    components: {IptalButton}
})
export default class EhliyetViewCard extends Vue {
    @Prop({})
    ehliyet!: EhliyetEntity;

    @Prop({})
    kisi!: KisiEntity;

    close() {
        this.$emit("close");
    }
}
