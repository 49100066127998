import {CariHareketTurEntity} from "@/entity/CariHareketTurEntity";


export class CariHareketTurListResponse {
    data!: CariHareketTurEntity[];
}

export function CariHareketTurListResponseProvider(depth:number=3): CariHareketTurListResponse {
    return {
        data: [
            {
                id: 1,
                tur: "Para Aktarma",
            },
            {
                id: 2,
                tur: "Tahsilat",
            },
            {
                id: 3,
                tur: "Masraflar",
            },
            {
                id: 4,
                tur: "Avans",
            },
            {
                id: 5,
                tur: "Harc",
            },
            {
                id: 6,
                tur: "Ödeme",
            },
        ]
    }
}
