import {HacizEntity} from "@/entity/Haciz/HacizTemelEntity";
import {MaasHacziEntity} from "@/entity/Haciz/MaasHacziEntity";
import {MaasHacziListResponseProvider} from "@/services/Haciz/MaasHacziResponse";


export class HacizListResponse{
    data!:HacizEntity[];
}

export function HacizListResponseProvider(depth:number=3):HacizListResponse{
    //let araclarResponse = Array<AracEntity>();
    let maaslarResponse = Array<MaasHacziEntity>();
    if(--depth > 0)
    {
       // araclarResponse = AracListResponseProvider(depth).data;
        maaslarResponse = MaasHacziListResponseProvider(depth).data;
    }
    return {
        data:maaslarResponse
    }
}


