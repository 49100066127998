






























































































































































import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import IlPicker from "@/components/pickers/IlPicker.vue";
import IlcePicker from "@/components/pickers/IlcePicker.vue";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";


@Component({
    components: {
        IlcePicker,
        IlPicker,
    },
})
export default class KimlikBilgileriForm extends Mixins(ObjectInputMixin) {

   mounted(){
       console.log("giden val:",this.localValue);
   }
}
