import {MasrafEntity} from "@/entity/MasrafEntity";
import {MasrafTuru} from "@/enum/MasrafTuru";
import {MasrafCesidi} from "@/enum/MasrafCesidi";
import { TakipListResponseProvider } from './TakipService';
import { TakipEntity } from '@/entity/TakipEntity';

export interface MasrafResponse {
    data: Array<MasrafEntity>
}

export function MasrafResponseProvider(depth:number=3): MasrafResponse {
    let takiplerResponse = Array<TakipEntity>();
    if(--depth > 0)
    {
        takiplerResponse = TakipListResponseProvider(depth).data;
    }
    return {
        data: [
            {
                id: 1,
                takip: takiplerResponse[0],
                masrafCesidi: MasrafCesidi.dosyamasrafi,
                masrafTarihi: new Date("2019-03-05"),
                masrafTutari: 200,
                masrafTuru: MasrafTuru.resmi,
                masrafYapanKisi: "Stendhal",
                masrafGirisTarihi: new Date("2019-03-05"),
                kasayaAjandayaAktar: true,
                masrafAciklamasi: "Evet bu masrafı ben yaptım - Stendhal",
                masrafBelgeliMi: false,
                masrafBelgesiAciklamasi: null,
                resmiyet:true
            },
            {
                id: 2,
                takip: takiplerResponse[0],
                masrafCesidi: MasrafCesidi.evrakmasrafi,
                masrafTarihi: new Date("2012-03-05"),
                masrafTutari: 100,
                masrafTuru: MasrafTuru.gayriresmi,
                masrafYapanKisi: "Lev Tolstoy",
                masrafGirisTarihi: new Date("2015-03-05"),
                kasayaAjandayaAktar: true,
                masrafAciklamasi: "Evet bu masrafı ben yaptım - Tolstoy",
                masrafBelgeliMi: true,
                masrafBelgesiAciklamasi: "Bu masraf belgeli bir masraftır",
                resmiyet:false
            }
        ]
    }
}