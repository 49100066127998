import {enum2select, SelectableListResponse} from "@/helpers/EnumHelper";
import {PersonelTuruLabel} from "@/enum/PersonelTuru";



export function PersonelTurListResponseProvider(depth:number=3): SelectableListResponse {
    return {
        data: enum2select(PersonelTuruLabel)
    };
}
