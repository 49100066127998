











import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import {IlceEntity} from "../../entity/IlceEntity";
import {IlceListResponseProvider} from "../../services/IlceService";
import {IlEntity} from "@/entity/IlEntity";
import {AxiosError, AxiosResponse} from "axios";
import {IcraDairesiListResponseProvider} from "@/services/IcraDairesiService";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component({})
export default class IlcePicker extends Mixins(ObjectInputMixin) {

    @Prop({default: null})
    il!: IlEntity | null;


    get message(): string {
        return (this.il ? '' : 'Lütfen İl Seçiniz');
    }

    close() {
        this.$emit("close");
    }

    items: Array<IlceEntity> = [];

    mounted() {
        this.loadIlceler();
        console.log("item:", this.items);
        console.log("il ne:", this.il);
    }

    @Watch('il')
    onIlChanged(newVal: IlEntity | null, oldVal: IlEntity | null) {
        console.log('onIlChanged from', newVal, 'to', oldVal);
        if (newVal == null) {
            this.items = [];
        }
        this.loadIlceler();

    }

    loadIlceler() {
        if (!this.il) {
            this.items = [];
            return;
        }
        this.items = IlceListResponseProvider().data.filter((item) => item.il?.id == this.il?.id);
        return;

        if (this.il) {
            this.$http.get('/api/pickList/il/' + this.il?.id + '/ilce').then((response: AxiosResponse<Array<IlceEntity>>) => {
                this.items = response.data;
            }).catch((err: AxiosError) => {

            });
        }
    }

}
