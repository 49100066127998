export enum HacizDurumu {
    yapilmadiadreshatali = "yapilmadiadreshatali",
    sirketkapali = "sirketkapali",
    yapildisirketcalismiyor = "yapildisirketcalismiyor",
    sirketfarklisirket = "sirketfarklisirket",
    hacizsirasi = "hacizsirasi",
    odemeyapilmayabaslanacak = "odemeyapilmayabaslanacak",
}

export enum HacizDurumuLabel {
    yapilmadiadreshatali = "Yapılamadı – Adres Hatalı",
    sirketkapali = "Yapılamadı – Şirket Kapalı",
    yapildisirketcalismiyor = "Yapıldı – Şirkette Çalışmıyor",
    sirketfarklisirket = "Yapıldı – Şirket Farklı Şirket",
    hacizsirasi = "Yapıldı – Haciz Sırası Var",
    odemeyapilmayabaslanacak = "Yapıldı – Ödeme Yapılmaya Başlanacak",

}