



















































import {Component, Prop, Vue} from "vue-property-decorator";
import {EhliyetEntity} from "../../../entity/EhliyetEntity";
import {KisiEntity} from "../../../entity/KisiEntity";
import {PasaportEntity} from "../../../entity/PasaportEntity";
import IptalButton from "@/components/inputs/IptalButton.vue";

@Component ({
    components: {IptalButton}
})
export default class PasaportViewCard extends Vue{
    @Prop({})
    pasaport!:PasaportEntity;

    @Prop({})
    kisi!:KisiEntity;

    close() {
        this.$emit("close");
    }
}
