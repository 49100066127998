export enum PersonelTuru{
    yoneticiAvukat= "yoneticiAvukat",
    kurucuAvukat="kurucuAvukat",
    avukat = "avukat",
    katip = "katip",
    stajyerAvukat = "stajyerAvukat",
    sekreter = "sekreter",
    muhasebe = "muhasebe",
    baskatip = "baskatip",
    idariYonetici = "idariYonetici",
    sysAdm = "sysAdm",
    diger = "diger"
}
export enum PersonelTuruLabel{
    yoneticiAvukat= "Yönetici Avukat",
    kurucuAvukat="Kurucu Avukat",
    avukat = "Avukat",
    katip = "Katip",
    stajyerAvukat = "Stajyer Avukat",
    sekreter = "Sekreter",
    muhasebe = "Muhasebe",
    baskatip = "Başkatip",
    idariYonetici = "İdari Yönetici",
    sysAdm = "Sistem Yöneticisi",
    diger = "Diğer"
}


