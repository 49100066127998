






















































import {Component, Vue} from "vue-property-decorator";
import PersonelBilgiDetay from "@/components/buro/personel/PersonelBilgiList.vue";
import HesapNoList from "@/components/comps/lists/HesapNoList.vue";
import IletisimList from "@/components/comps/lists/IletisimList.vue";
import AdresList from "@/components/comps/lists/AdresList.vue";
import {KisiEntity} from "@/entity/KisiEntity";
import {KurumEntity} from "@/entity/KurumEntity";
import KimlikBilgileriList from "@/components/comps/lists/KimlikBilgileriList.vue";
import MuvekkilGenel from "@/components/vekalet/muvekkil/MuvekkilGenel.vue";
import {MuvekkilListResponseProvider} from "@/services/MuvekkilService";
import TakipList from "@/components/takip/TakipList.vue";
import CariHareketList from "@/components/comps/lists/CariHareketList.vue";

@Component({
    components: {
        CariHareketList,
        TakipList,
        MuvekkilGenel,
        KimlikBilgileriList,
        AdresList,
        IletisimList,
        HesapNoList,
        PersonelBilgiDetay,

    }
})
export default class MuvekkilDetay extends Vue {

    item: KisiEntity | KurumEntity | null = null;


    mounted() {

        window.setTimeout(() => {
            let index: number = parseInt(this.$route.params.id) - 1;
            console.log("index : ", index);
            this.item = MuvekkilListResponseProvider().data[index].kisiKurum;
            console.log("Muvekkil: ", this.item);
        }, 200);


    }

}
