




















































































































import IptalButton from "../../inputs/IptalButton.vue";
import kaydetButton from "../../inputs/KaydetButton.vue";
import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import TcKimlikNumarasi from "@/components/inputs/TcKimlikNumarasi.vue";
import {PersonelTurListResponseProvider} from '@/services/PersonelTurService';
import {PersonelTuru, PersonelTuruLabel} from "@/enum/PersonelTuru";
import {AvukatPersonelEntity} from "@/entity/AvukatPersonelEntity";
import {DigerPersonelEntity} from "@/entity/DigerPersonelEntity";
import {VekilTipi} from "@/enum/VekilTipi";
import {PersonelIseGirisCikisEntity} from "@/entity/PersonelIseGirisCikisEntity";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import CinsiyetPicker from "@/components/pickers/CinsiyetPicker.vue";

@Component({
    components: {
        CinsiyetPicker,
        TcKimlikNumarasi,
        IptalButton,
        kaydetButton,

    },
})
export default class PersonelBilgiForm extends Mixins(ObjectInputMixin) {
    turlerEnum = PersonelTuru;
    turlerLabelEnum = PersonelTuruLabel;
    vekilTipiEnum = VekilTipi;
    turler = PersonelTurListResponseProvider().data;

    get isAvukatPersonel(): boolean {
        return this.localValue.personelTuru == this.turlerEnum.avukat
            ||
            this.localValue.personelTuru == this.turlerEnum.stajyerAvukat
            ||
            this.localValue.personelTuru == this.turlerEnum.kurucuAvukat
            ||
            this.localValue.personelTuru == this.turlerEnum.yoneticiAvukat
            ;
    }
}

