import {KimlikBelgesiEntity} from "@/entity/KimlikBelgesiEntity";
import {IlEntity} from "@/entity/IlEntity";
import {IlceEntity} from "@/entity/IlceEntity";
import {KimlikBelgesiTuru} from "@/enum/KimlikBelgesiTuru";

export class AvukatKimlikKartiEntity extends KimlikBelgesiEntity {
    barokartNo: string | null = null;
    seri: string | null = null;
    seriNo: number | null = null;
    kanGrubu: string | null = null;
    babaAdi: string | null = null;
    anneAdi: string | null = null;
    mahalleKoy: string | null = null;
    ciltNo: number | null = null;
    aileSiraNo:number | null = null;
    constructor() {
        super();
        this.kimlikBelgesiTuru = KimlikBelgesiTuru.avukatKimlikKarti;
    }
}
