

























































































































































import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import DeleteButton from "@/components/common-inputs/DeleteButton.vue";
import KaydetButton from "@/components/inputs/KaydetButton.vue";
import IptalButton from "@/components/inputs/IptalButton.vue";
import {ItemArrayManagerTableHeader, TableHeader} from "@/interface/TableHeader";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";

@Component({
    components: { FormDialogButton, IptalButton, KaydetButton, DeleteButton}
})
export default class ItemArrayManager extends Vue {
    @Prop({required: true})
    value!: Array<any>;

    @Prop({required: true})
    headers!: Array<ItemArrayManagerTableHeader>;

    @Prop({required: true})
    title!: string;

    @Prop() color!: string;

    private toolbarRenk = this.color ? this.color : 'white'

    @Prop() btncolor!: string;

    private colorbtn = this.btncolor ? this.btncolor : 'white'

    @Prop()
    ekleLabel!: string;

    @Prop({default: 'table'})
    type?: 'table' | 'list' | 'text' | 'headless';

    @Prop({default: false})
    editable: any;

     @Prop({default: false})
    detail: any;

    @Prop({default: false})
    addable: any;

    @Prop({default: false})
    deletable: any;

    @Prop({default: false})
    alacakKalemi: any;

    @Prop({default: false})
    crud: any;



    get getEkleLabel() {
        if (this.ekleLabel) {
            return this.ekleLabel;
        }
        return "Ekle";
    }

    get isHeadless(): boolean {
        return this.type === 'headless';
    }

    get isTable(): boolean {
        return this.type === 'table';
    }

    get isText(): boolean {
        return this.type === 'text';
    }

    get isList(): boolean {
        return this.type === 'list';
    }

    getDescendantProp(obj: any, desc: string) {
        let arr: Array<string> = desc.split(".");
        while (arr?.length){
            let index = arr.shift();
            if(typeof index == "string"){
                obj = obj[index];
            }
        }
        return obj;
    }

    get isDeletable(): boolean {
        return this.deletable === "" || this.deletable || this.crud || this.crud === "";
    }

    get isEditable(): boolean {
        return this.editable === "" || this.editable || this.crud || this.crud === "";
    }

     get isDetail(): boolean {
        return this.detail === "" || this.detail;
    }

    get isAddable(): boolean {
        //console.log("this.addable", this.addable)
        // console.log("this.crud", this.crud)

        return this.addable === "" || this.addable || this.crud || this.crud === "";
    }

    get actionsWidth(): number {
        return 32 + (this.isDeletable ? 36 : 0) + (this.isEditable ? 36 : 0) + (this.isDetail ? 36 : 0);
    }

    deleteItem(item: number) {
        const index = this.value.indexOf(item);
        console.log("found index to remove ", index);
        let arr = new Array<any>();
        if (index > -1) {
            this.$emit('input',
                arr.concat(
                    this.value.slice(0, index)
                ).concat(
                    this.value.slice(index + 1)
                )
            );
        }


    }



    addItem(o: any) {
        if (!this.value) {
            this.$emit('input', [o]);
        } else {
            if (Array.isArray(this.value)) {
                this.$emit('input', this.value.concat([o]));
            } else {
                this.$emit('input', [o]);
            }
        }

    }


}
