import {TakipEntity} from "@/entity/TakipEntity";
import {TakipTalebiListResponseProvider} from "@/services/TakipTalebiService";
import {BorcluListResponseProvider} from "@/services/BorcluService";
import {MasrafResponseProvider} from "@/services/MasrafService";
import {IlamBilgisiListResponseProvider} from "@/services/IlamBilgisiService";
import {AlacakliListResponseProvider} from "@/services/AlacakliService";
import {VekaletListResponseProvider} from "@/services/VekaletService";
import { VekaletEntity } from '@/entity/VekaletEntity';
import { AlacakliEntity } from '@/entity/AlacakliEntity';
import { BorcluEntity } from '@/entity/BorcluEntity';
import { MasrafEntity } from '@/entity/MasrafEntity';
import { IlamliAlacakKalemiEntity } from '@/entity/AlacakBelgeler/IlamliAlacakKalemiEntity';
import { IlamsizAlacakKalemiEntity } from '@/entity/AlacakBelgeler/IlamsizAlacakKalemiEntity';
import { HarcResponseProvider } from './HarcService';
import { TakipTalebiEntity } from '@/entity/TakipTalebiEntity';
import { HarcKalemiEntity } from '@/entity/HarcKalemiEntity';
import { IlamBilgisiEntity } from '@/entity/IlamBilgisiEntity';
import { IlamsizAlacakKalemiListResponseProvider } from './AlacakBelgeleri/IlamsizAlacakKalemiService';
import { IlamliAlacakKalemiListResponseProvider } from './AlacakBelgeleri/IlamliAlacakKalemiService';

export class TakipListResponse {
    data!: TakipEntity[];
}

export function TakipListResponseProvider(depth:number=5): TakipListResponse { //depth değiştirmek gerekiyor (3) ilamli için
    let vekaletlerResponse = Array<VekaletEntity>();
    let alacaklilarResponse = Array<AlacakliEntity>();
    let borclularResponse = Array<BorcluEntity>();
    let ilamliAlacakKalemleriResponse = Array<IlamliAlacakKalemiEntity>();
    let ilamsizAlacakKalemleriResponse = Array<IlamsizAlacakKalemiEntity>();
    let masraflarResponse = Array<MasrafEntity>();
    let takipTalepleriResponse = Array<TakipTalebiEntity>();
    let harclarResponse = Array<HarcKalemiEntity>();
    let ilamBilgilerResponse = Array<IlamBilgisiEntity>();
    if(--depth > 0)
    {
        vekaletlerResponse = VekaletListResponseProvider(depth).data;
        alacaklilarResponse = AlacakliListResponseProvider(depth).data;
        borclularResponse = BorcluListResponseProvider(depth).data;
        ilamliAlacakKalemleriResponse = IlamliAlacakKalemiListResponseProvider(depth).data;
        ilamsizAlacakKalemleriResponse = IlamsizAlacakKalemiListResponseProvider(depth).data;
        masraflarResponse = MasrafResponseProvider(depth).data;
        takipTalepleriResponse = TakipTalebiListResponseProvider(depth).data;
        harclarResponse = HarcResponseProvider(depth).data;
        ilamBilgilerResponse = IlamBilgisiListResponseProvider(depth).data;
    }
    return {    
        data: [
            {
                id: 1,
                takipTalebi: takipTalepleriResponse[0],
                alacaklilar: [],
                borclular: borclularResponse,
                vekaletler: [],
                masraflar: masraflarResponse,
                harclar: harclarResponse[0],
                ilamBilgisi: ilamBilgilerResponse[0],
                alacakKalemleri : ilamliAlacakKalemleriResponse,
            },
            {
                id: 2,
                takipTalebi: takipTalepleriResponse[1],
                alacaklilar: [],
                borclular: borclularResponse,
                vekaletler: [],
                masraflar: masraflarResponse,
                harclar: harclarResponse[0],
                ilamBilgisi: ilamBilgilerResponse[0],
                alacakKalemleri : ilamsizAlacakKalemleriResponse,
            }
        ]
    }
}
