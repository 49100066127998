import {HacizTuru} from "@/enum/HacizTuru";
import {YedieminEntity} from "@/entity/Haciz/YedieminEntity";
import {IstihkakEntity} from "@/entity/Haciz/IstihkakEntity";
import {YedieminListResponseProvider} from "@/services/Haciz/YedieminResponse";
import {IstihkakListResponseProvider} from "@/services/Haciz/IstihkakResponse";
import {MaasHacziEntity} from "@/entity/Haciz/MaasHacziEntity";
import {AdresEntity} from "@/entity/AdresEntity";
import {AdresListResponseProvider} from "@/services/AdresService";
import {TebligatDurumu} from "@/enum/TebligatDurumu";
import {HacizDurumu} from "@/enum/HacizDurumu";

export class MaasHacziResponse {
    data!: MaasHacziEntity[];
}

export function MaasHacziListResponseProvider(depth: number = 3): MaasHacziResponse {
    let yedieminResponse = Array<YedieminEntity>();
    let istihkakResponse = Array<IstihkakEntity>();
    let adresRespnse = Array<AdresEntity>();
    if (--depth > 0) {
        yedieminResponse = YedieminListResponseProvider(depth).data;
        istihkakResponse = IstihkakListResponseProvider(depth).data;
        adresRespnse = AdresListResponseProvider(depth).data;


    }
    return {
        data: [
            {
                id: 1,
                hacizTuru: HacizTuru.maas,
                hacizTarihi: new Date("2020-01-08"),
                talimatTarihi: new Date("2020-01-01"),
                hacizAvansiYatirildiMi: true,
                hacizAvansYatirilmaTarihi: new Date("2020-01-03"),
                yenilemeMi: true,
                hacizSirasi: 1,
                borcluyaTebligatYapildiMi: false,
                yediemin: yedieminResponse[0],
                istihkak: istihkakResponse[0],
                baskaHacizVar: true,
                baskaRehinVar: false,
                muzekkereYazdirilmis:false,
                gonderimTarihi:new Date("2020-01-03"),
                tebligDurumu: TebligatDurumu.gonderildi,
                tebligTarihi: new Date("2020-01-03"),
                barkodNo: "12312",
                hacizDurumu:HacizDurumu.hacizsirasi,
                isverenler: [
                    {
                        id:1,
                        kisiKurumAd: "Galatasaray SK",
                        adres: adresRespnse[0],
                    }
                ],
                gerceklestiMi: false,
                satis: null,
            },
            {
                id: 2,
                hacizTuru: HacizTuru.maas,
                hacizTarihi: new Date("2021-01-08"),
                talimatTarihi: new Date("2021-01-01"),
                hacizAvansiYatirildiMi: true,
                hacizAvansYatirilmaTarihi: new Date("2021-01-03"),
                yenilemeMi: true,
                hacizSirasi: 2,
                borcluyaTebligatYapildiMi: false,
                yediemin: yedieminResponse[0],
                istihkak: istihkakResponse[0],
                baskaHacizVar: true,
                baskaRehinVar: false,
                muzekkereYazdirilmis:false,
                gonderimTarihi:new Date("2020-01-03"),
                tebligDurumu: TebligatDurumu.gonderildi,
                tebligTarihi: new Date("2020-01-03"),
                barkodNo: "12312",
                hacizDurumu:HacizDurumu.hacizsirasi,
                isverenler: [
                    {
                        id:2,
                        kisiKurumAd: "Sabancı LTD",
                        adres: adresRespnse[0],
                    }
                ],
                gerceklestiMi: false,
                satis: null,
            },

        ]
    }
}


