import {TahsilatEntity} from "@/entity/TahsilatEntity";
import {ParaBirimiListResponseProvider} from "@/services/ParaBirimiService";
import {TahsilatTuru} from "@/enum/TahsilatTuru";
import {BorcluListResponseProvider} from "@/services/BorcluService";
import {HarcOraniResponseProvider} from "@/services/HarcOraniService";
import { BorcluEntity } from '@/entity/BorcluEntity';
import { ParaBirimiEntity } from '@/entity/ParaBirimiEntity';
import {HarcOraniEntity } from '@/entity/HarcOraniEntity';

export interface TahsilatResponse {
    data: Array<TahsilatEntity>
}

export  function TahsilatListResponseProvider(depth:number=3):TahsilatResponse {
    let borclularResponse = Array<BorcluEntity>();
    let paraBirimleriResponse = Array<ParaBirimiEntity>();
    let harcOranlariResponse = Array<HarcOraniEntity>();
    if(--depth > 0)
    {
        borclularResponse = BorcluListResponseProvider(depth).data;
        paraBirimleriResponse = ParaBirimiListResponseProvider(depth).data;
        harcOranlariResponse = HarcOraniResponseProvider(depth).data;
    }
    return {
        data:[
            {
                id:1,
                borclu: borclularResponse[0],
                tarih : new Date("2007-03-15"),
                tutar: 200,
                netTutar:200,
                paraBirimi : paraBirimleriResponse[0],
                harcOrani: harcOranlariResponse[2],
                tahsilatiYapanKisi: "Kamile Baysal",
                tahsilatGirisTarihi: new Date("2007-06-09"),
                kasayaAjandayaAktar: true,
                tahsilHarciBelgeNo: "100001",
                vekaletUcretiBelgeNo: "11119",
                tahsilatTuru:TahsilatTuru.bantahsilat,
            },
            {
                id:2,
                borclu: borclularResponse[1],
                tarih : new Date("2009-02-25"),
                tutar: 100,
                netTutar:100,
                paraBirimi : paraBirimleriResponse[0],
                harcOrani: harcOranlariResponse[0],
                tahsilatiYapanKisi: "Kamile Baysal",
                tahsilatGirisTarihi: new Date("2009-06-09"),
                kasayaAjandayaAktar: false,
                tahsilHarciBelgeNo: "100002",
                vekaletUcretiBelgeNo: "11116",
                tahsilatTuru:TahsilatTuru.bantahsilat,
            }
        ]
    }
}
