import {KimlikBelgesiEntity} from "@/entity/KimlikBelgesiEntity";
import {KimlikBelgesiTuru} from "@/enum/KimlikBelgesiTuru";
import { IlceEntity } from './IlceEntity';

export class EhliyetEntity extends KimlikBelgesiEntity {
    ehliyetNo: string | null = null;
    verildigiYer: IlceEntity | null = null;
    constructor() {
        super();
        this.kimlikBelgesiTuru = KimlikBelgesiTuru.ehliyet;
    }
}
