export enum HacizTuru {
    arac = "arac",
    maas = "maas",
    ihbarname = "ihbarname",
    muzekkere = "muzekkere",
    hissekarpayi = "hissekarpayi",
    markapatent = "markapatent",
    gemi = "gemi",
    tasinmaz = "tasinmaz",
    tasinir = "tasinir"
}

export enum HacizTuruLabel {
    arac = "Araç Haczi",
    maas = "Maaş Haczi",
    ihbarname = "İhbarname Alacak Haczi",
    muzekkere = "Müzekkere Alacak Haczi",
    hissekarpayi = "Hisse/Kar Payı Haczi",
    markapatent = "Marka/Patent Haczi",
    gemi = "Gemi Haczi",
    tasinmaz = "Taşınmaz Haczi",
    tasinir = "Taşınır Haczi"
}

export enum HacizTuruIcon {
    arac = "mdi-truck-outline",
    maas = "mdi-bitcoin",
    ihbarname = "mdi-file-chart",
    muzekkere = "mdi-file-chart",
    hissekarpayi = "mdi-bitcoin",
    markapatent = "mdi-bookmark-multiple",
    gemi = "Gemi Haczi",
    tasinmaz = "Taşınmaz Haczi",
    tasinir = "Taşınır Haczi"
}
