




























import {Component, Vue} from "vue-property-decorator";
import VergiNumarasi from "@/components/inputs/VergiNumarasi.vue";

@Component({
    components: {VergiNumarasi}
})
export default class Kosullar extends Vue{

}
