import {IlEntity} from "@/entity/IlEntity";
import {TakipTipiEntity} from "@/entity/TakipTipiEntity";
import {TakipAltTipiEntity} from "@/entity/TakipAltTipiEntity";
import {TakipYoluEntity} from "@/entity/TakipYoluEntity";
import {IcraDairesiEntity} from "@/entity/IcraDairesiEntity";
import {IlamTuru} from "@/enum/IlamTuru";


export class TakipTalebiEntity {
    kartNo: number | null = null;
    takipTipi: TakipTipiEntity | null = null;
    takipAltTipi: TakipAltTipiEntity | null = null;
    takipYolu: TakipYoluEntity | null = null;
    il: IlEntity | null = null;
    icraDairesi: IcraDairesiEntity | null = null;
    dosyaEsasNo: string | null = null;
    takipTarihi: Date | null = null;
    ilamTuru: IlamTuru | null = null;
    hesapTarihi: Date | null = null;
    takipDurumu: string | null = null; // Enum,Entitiy? varsayılan adın değişmesi durumu?

    ekAlan1:string|null=null;
    ekAlan2:string|null=null;
    ekAlan3:string|null=null;
    ekAlan4:string|null=null;
    ekAlan5:string|null=null;

    constructor(defaults:any=null) {
        // @ts-ignore
        if(typeof defaults == "object"){
            console.log("TakipTalebiEntity::constructor",defaults);
            Object.assign(this,defaults ? defaults : {} );
        }

        // if(!this.takipTipi){
        //     this.takipTipi = new TakipTipiEntity({});
        // }
    }

}
