












































































import {Component, Vue} from "vue-property-decorator";
import DeleteButton from "@/components/common-inputs/DeleteButton.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import Dates from "@/components/inputs/Dates.vue";
import CariHareketForm from "@/components/comps/forms/CariHareketForm.vue";
import {CariHareketTurListResponseProvider} from "@/services/CariHareketTurService";
import {CariHareketListResponseProvider} from "@/services/CariHareketService";
import {CariHareketEntity} from "@/entity/CariHareketEntity";

@Component({
    components: {CariHareketForm, Dates, FormDialogButton, DeleteButton}
})
export default class CariHareketList extends Vue {
    CariHareketler = CariHareketListResponseProvider().data;
    item = "";
    search: string = "";
    hesap = new Hesap();

    mounted() {
        this.Hesapla();

    }

    Hesapla() {
        this.hesap.toplamCikis = 0;
        this.hesap.toplamGiris = 0;
        this.hesap.toplamFark = 0;
        this.hesap.toplamFarkArtiMi = true;
        this.CariHareketler.forEach((cariHareket: CariHareketEntity) => {
            if (cariHareket.tutar != null) {
                if (cariHareket.artiMi) {
                    this.hesap.toplamGiris += cariHareket.tutar;
                } else {
                    this.hesap.toplamCikis += cariHareket.tutar;
                }
            }
        })
        this.hesap.toplamFark = this.hesap.toplamGiris - this.hesap.toplamCikis;
        if (this.hesap.toplamFark < 0) {
            this.hesap.toplamFarkArtiMi = false;
            this.hesap.toplamFark = Math.abs(this.hesap.toplamFark);
        }

    }

    Turler = CariHareketTurListResponseProvider().data;
    aranacakTur = null;
    aranacakYon = null;
    yonler = ["Gelen Para +++", "Giden Para ---"]
    headers = [
        {
            text: "Tarih",
            align: "start",
            value: "tarih"
        },
        {
            text: "Tür",
            align: "start",
            value: "tur.tur"
        },
        {
            text: "Tutar",
            align: "start",
            value: "tutar"
        },
        {
            text: "İşlemler",
            align: "start",
            value: "actions"
        },

    ];
}

class Hesap {
    toplamCikis!: number;
    toplamGiris!: number;
    toplamFark!: number;
    toplamFarkArtiMi!: boolean;
}
