





import {Component, Prop, Vue} from "vue-property-decorator";
import {AdresEntity} from "../../../entity/AdresEntity";
import AdresForm from "@/components/comps/forms/AdresForm.vue";
import DeleteButton from "@/components/common-inputs/DeleteButton.vue";

@Component({
    components: {DeleteButton, AdresForm}
})
export default class AdresListLine extends Vue {
    @Prop({required: true})
    item!: AdresEntity;

    @Prop({})
    editable: boolean = true;

    dialog: boolean = false;

    edit(item: AdresEntity) {
        this.dialog = true;
    }


}
