import {YedieminEntity} from "@/entity/Haciz/YedieminEntity";
import {AdresEntity} from "@/entity/AdresEntity";
import {AdresListResponseProvider} from "@/services/AdresService";
import {IstihkakEntity} from "@/entity/Haciz/IstihkakEntity";


export class IstihkakResponse{
    data!:IstihkakEntity[];
}

export function IstihkakListResponseProvider(depth:number=3):IstihkakResponse{
    let adreslerResponse = Array<AdresEntity>();
    if(--depth > 0)
    {
        adreslerResponse = AdresListResponseProvider(depth).data;
    }
    return {
        data:[
            {
                kisiTc: "50662455018",
                kisiAdSoyad: "Kamile Baysal",
                kisiAdres: adreslerResponse[0],
                malCins: "mal cinsi",
                malOzellik:"mal özelliği",
                malDeger: 2500,
            },
            {
                kisiTc: "70312452018",
                kisiAdSoyad: "Deniz Erdinç",
                kisiAdres: adreslerResponse[0],
                malCins: "mal cinsi",
                malOzellik:"mal özelliği",
                malDeger: 2500,
            },

        ]
    }
}


