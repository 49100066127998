import {TebligatEntity} from "@/entity/TebligatEntity";
import {TebligatDurumu} from "@/enum/TebligatDurumu";
import {AdresEntity} from "@/entity/AdresEntity";
import {AdresListResponseProvider} from "@/services/AdresService";
import {GonderilenTuru} from "@/enum/GonderilenTuru";
import {TebligatSekli} from "@/enum/TebligatSekli";
import {TebligatAlanEntity} from "@/entity/TebligatAlanEntity";
import {TebligatAlanListResponseProvider} from "@/services/TebligatAlanService";
import {BorcluEntity} from "@/entity/BorcluEntity";
import {BorcluListResponseProvider} from "@/services/BorcluService";

export interface TebligatResponse {
    data: Array<TebligatEntity>
}

export function TebligatListResponseProvider(depth: number = 3, id: number): TebligatResponse {
    let adreslerResponse = Array<AdresEntity>();
    let tebligatAlanlarResponse = Array<TebligatAlanEntity>();
    let borcluResponse = Array<BorcluEntity>();
    if(--depth > 0)
    {
        borcluResponse = BorcluListResponseProvider(depth).data;
        adreslerResponse = AdresListResponseProvider(depth).data;
        tebligatAlanlarResponse = TebligatAlanListResponseProvider(depth).data;
    }
    if (id === 1) {
        return {
            data: [
                {
                    id: 1,
                    barkod: "2151512258",
                    borclu: borcluResponse[0],
                    tebligatSekli:TebligatSekli.elektronik,
                    tebligTarihi: new Date("2020-01-01"),
                    gonderilen:GonderilenTuru.arastirma,
                    kesinlesmeTarihi: new Date("2020-01-01"),
                    sonOdemeTarihi: new Date("2020-01-01"),
                    tebligatAlan: tebligatAlanlarResponse[1],
                    teslimEdilenAdSoyad: "Kerimcan Durur",
                    malBeyanindaBulunduMu: true,
                    itirazVarMi: true,
                    itirazTarihi: new Date("2020-01-01"),
                    tebligatGonderimTarihi: new Date("2020-01-01"),
                    tebligatDurumu: TebligatDurumu.tebligedildi,
                    adres:adreslerResponse[0],
                    bilaSebebi:null,
                    bilaTarihi:null,
                    arastirmaIstemeTarihi: null,
                    arastirmaKodu: null,
                    yanit:null,
                    yeniAdresGondermeTarihi:null,
                    ilanVeya35Teblig:null,
                },
            ]
        }
    } else {
        return {
            data: [
                {
                    id: 2,
                    barkod: "2151512251",
                    borclu: borcluResponse[2],
                    tebligatSekli:TebligatSekli.normal,
                    gonderilen:GonderilenTuru.takiptalebi,
                    tebligTarihi: new Date("2020-01-01"),
                    kesinlesmeTarihi: new Date("2020-01-01"),
                    sonOdemeTarihi: new Date("2020-01-01"),
                    tebligatAlan: tebligatAlanlarResponse[2],
                    teslimEdilenAdSoyad:null,
                    malBeyanindaBulunduMu: true,
                    itirazVarMi: true,
                    itirazTarihi: new Date("2020-01-01"),
                    tebligatGonderimTarihi: new Date("2020-01-01"),
                    tebligatDurumu: TebligatDurumu.tebligedildi,
                    adres:adreslerResponse[0],
                    bilaSebebi:null,
                    bilaTarihi:null,
                    arastirmaIstemeTarihi: null,
                    arastirmaKodu: null,
                    yanit:null,
                    yeniAdresGondermeTarihi:null,
                    ilanVeya35Teblig:null,

                },
                {
                    id: 3,
                    barkod: "2151512252",
                    borclu: borcluResponse[2],
                    tebligatSekli:TebligatSekli.elektronik,
                    gonderilen:GonderilenTuru.diger,
                    tebligTarihi: null,
                    kesinlesmeTarihi: null,
                    sonOdemeTarihi: null,
                    tebligatAlan: tebligatAlanlarResponse[4],
                    teslimEdilenAdSoyad: null,
                    malBeyanindaBulunduMu: null,
                    itirazVarMi: null,
                    itirazTarihi: null,
                    tebligatGonderimTarihi: new Date("2020-01-01"),
                    tebligatDurumu: TebligatDurumu.bilaoldu,
                    adres:adreslerResponse[0],
                    bilaSebebi:null,
                    bilaTarihi:null,
                    arastirmaIstemeTarihi: null,
                    arastirmaKodu: null,
                    yanit:null,
                    yeniAdresGondermeTarihi:null,
                    ilanVeya35Teblig:null,
                }
            ]
        }
    }

}
