












import {mask} from "vue-the-mask";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {Component, Mixins} from "vue-property-decorator";

@Component({
    directives:{
        mask
    }
})
export default class DosyaEsasNumarasi extends Mixins(SimpleInputMixin){

    maskStr= "####/#######";

    rules= [
        (value:string) => !!value || "Zorunlu."
    ];

    validate() {
        this.$emit("value", this.localValue);
    }
};
