import {FaizEntity} from "@/entity/FaizEntity";
import {FaizPeriyot} from "@/enum/FaizPeriyot";
import {FaizOranListResponseProvider} from "@/services/FaizOranService";
import { FaizOranGecmisEntity } from '@/entity/FaizOranGecmisEntity';


class FaizListResponse {
    data!: FaizEntity[]
}

export function FaizListResponseProvider(depth:number=3): FaizListResponse {
    let faizOranlarResponse = Array<FaizOranGecmisEntity>();
    if(--depth > 0)
    {    
        faizOranlarResponse = FaizOranListResponseProvider(depth).data;
    }
    return {
        data: [
            {
                id: 1,
                kod: 1,
                ad: "Yasal Faiz",
                periyot: FaizPeriyot.aylik,
                faizGecmis: faizOranlarResponse,
                standartMi: true
            },

            {
                id: 2,
                kod: 1,
                ad: "Yasal Faiz",
                periyot: FaizPeriyot.aylik,
                faizGecmis: faizOranlarResponse,
                standartMi: true
            },

            {
                id: 3,
                kod: 1,
                ad: "Yasal Faiz",
                periyot: FaizPeriyot.aylik,
                faizGecmis: faizOranlarResponse,
                standartMi: true
            },

            {
                id: 4,
                kod: 2,
                ad: "Reeskont Avans Faiz",
                periyot: FaizPeriyot.aylik,
                faizGecmis: faizOranlarResponse,
                standartMi: true
            },
            {
                id: 5,
                kod: 3,
                ad: "Reeskont İskonto Faiz",
                periyot: FaizPeriyot.aylik,
                faizGecmis: faizOranlarResponse,
                standartMi: true
            },
            {
                id: 6,
                kod: 4,
                ad: "Özelleştirilmiş Faiz",
                periyot: FaizPeriyot.yillik,
                faizGecmis: faizOranlarResponse,
                standartMi: false
            }
        ]
    }
}
