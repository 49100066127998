import {ProtestoIhtarEnum} from "@/enum/ProtestoIhtarEnum";
import {NoterListResponseProvider} from "@/services/NoterService";
import {ParaBirimiListResponseProvider} from "@/services/ParaBirimiService";
import {IhtarEntity} from "@/entity/AlacakBelgeler/IhtarEntity";
import { NoterEntity } from '@/entity/NoterEntity';
import { ParaBirimiEntity } from '@/entity/ParaBirimiEntity';


class IhtarListResponse {
    data!:IhtarEntity []
}

export function IhtarListResponseProvider(depth:number=3): IhtarListResponse {
    let noterlerResponse = Array<NoterEntity>();
    let paraBirimleriResponse = Array<ParaBirimiEntity>();
    if(--depth > 0)
    {
        noterlerResponse = NoterListResponseProvider(depth).data;
        paraBirimleriResponse = ParaBirimiListResponseProvider(depth).data;
    }
    return {
        data: [
            {
                id:1,
                tur: ProtestoIhtarEnum.ihtar,
                noter: noterlerResponse[0],
                yevmiyeNo: 12324,
                tarih: new Date("2012-01-01"),
                tutar: 2450,
                paraBirimi: paraBirimleriResponse[0],
            },
            {
                id:2,
                tur: ProtestoIhtarEnum.ihtar,
                noter: noterlerResponse[0],
                yevmiyeNo: 12325,
                tarih: new Date("2012-01-02"),
                tutar: 2452,
                paraBirimi: paraBirimleriResponse[0],
            },
        ]
    }
}
