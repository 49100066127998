













































































import {Component, Prop, Vue} from "vue-property-decorator";
import {KisiEntity} from "../../../entity/KisiEntity";
import {AvukatKimlikKartiEntity} from "@/entity/AvukatKimlikKartiEntity";
import IptalButton from "@/components/inputs/IptalButton.vue";

@Component({
    components: {IptalButton}
})
export default class AvukatKimlikKartiViewCard extends Vue {
    @Prop({})
    avukatKimlikKarti!: AvukatKimlikKartiEntity;

    @Prop({})
    kisi!: KisiEntity;

    close() {
        this.$emit("close");
    }
}
