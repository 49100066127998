import {AlacakKalemiTuru} from "@/enum/AlacakKalemiTuru";
import {IlamsizAlacakKalemiEntity} from "@/entity/AlacakBelgeler/IlamsizAlacakKalemiEntity";
import {IlamsizBelgeTipi} from "@/enum/IlamsizBelgeTipi";
import {ParaBirimiListResponseProvider} from "@/services/ParaBirimiService";
import {CekListResponseProvider} from "@/services/AlacakBelgeleri/CekService";
import { CekEntity } from '@/entity/AlacakBelgeler/CekEntity';


class AlacakKalemleriResponse {
    data!: Array<IlamsizAlacakKalemiEntity>
}

export function IlamsizAlacakKalemiListResponseProvider(depth:number=3): AlacakKalemleriResponse {
        let ceklerResponse = Array<CekEntity>();
        if(--depth > 0)
        {
            ceklerResponse = CekListResponseProvider(depth).data;
        }
        return {
        data:
        [
            ceklerResponse[0],
            ceklerResponse[1],
        ]
    }
}
