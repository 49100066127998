import {KisiKurumEntity} from "@/entity/KisiKurumEntity";
import {KisiEntity} from "@/entity/KisiEntity";
import {KamuOzel} from "@/enum/KamuOzel";
import {KisiKurumTuru} from "@/enum/KisiKurumTuru";

export class KurumEntity extends KisiKurumEntity{
    ad: string | null = null;
    vergiNo: number | null = null;
    vergiDairesi: string | null = null;
    ortaklar: Array<KisiEntity> = [];
    ticaretSicilNo: number | null = null;
    mersisNo: string | null = null;
    kamuOzel: KamuOzel | null = null;
    kisiKurumTuru = KisiKurumTuru.sirket;
}
