export enum IlamsizBelgeTipi {
    cek = "cek",
    cariHesapAlacak = "cariHesapAlacak",
    cezaiSart = "cezaiSart",
    fatura = "fatura",
    kiraFarkiAlacak = "kiraFarkiAlacak",
    kiraKontrat = "kiraKontrat",
    police = "police",
    senet = "senet",
    sozlesme = "sozlesme",
    tuketiciHakemHeyetiKarar = "tuketiciHakemHeyetiKarar",
    diger="diger"
}

export enum IlamsizBelgeTipiLabel {
    cek = "Çek",
    cariHesapAlacak = "Cari Hesap Alacak",
    cezaiSart = "Cezai Şart",
    fatura = "Fatura",
    kiraFarkiAlacak = "Kira Farkı Alacağı",
    kiraKontrat = "Kira Kontratı",
    police = "Poliçe",
    senet = "Senet",
    sozlesme = "Sözleşme",
    tuketiciHakemHeyetiKarar = "Tüketici Hakem Heyeti Kararı",
    ilamli = 'İlam',
    diger='Diğer'
}
