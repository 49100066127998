export enum IlamTipi {
    mahkeme = "mahkeme",
    hakemkarari = "hakemkarari",
    diger = "diger",
}

export enum IlamTipiLabel {
    mahkeme = "Mahkeme",
    hakemkarari = "Hakem Kararı",
    diger = "Diğer",
}
