











































































import IptalButton from "../../inputs/IptalButton.vue";
import kaydetButton from "../../inputs/KaydetButton.vue";
import {Component, Prop, Vue} from "vue-property-decorator";
import TcKimlikNumarasi from "@/components/inputs/TcKimlikNumarasi.vue";
import {KisiEntity} from "@/entity/KisiEntity";
import {KurumEntity} from "@/entity/KurumEntity";
import {KisiKurumTuru} from "@/enum/KisiKurumTuru";

@Component({
    components: {
        TcKimlikNumarasi,
        IptalButton,
        kaydetButton,

    },
})
export default class GenelDuzenle extends Vue {
    @Prop({required: true})
    item!: KisiEntity | KurumEntity;

    close() {
        this.$emit("cancel");
    }

    get sahisMi(): boolean {
        return this.item.kisiKurumTuru == KisiKurumTuru.sahis;
    }

    mounted() {
        window.setTimeout(() => {


            //console.log("kisi mi: ",this)
        }, 200);
    }
}

