

















import {Component, Mixins} from "vue-property-decorator";
import {IlEntity} from "@/entity/IlEntity";
import {IcraDairesiEntity} from "@/entity/IcraDairesiEntity";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import IcraDairesiList from "@/components/comps/lists/IcraDairesiList.vue";
import {IcraDairesiListResponseProvider} from "@/services/IcraDairesiService";

@Component({
    components: {IcraDairesiList}
})
export default class IcraDairesiPicker extends Mixins(ObjectInputMixin) {
    dialog = false;
    gecmisIcraDaireleri: IcraDairesiEntity[] = [];
    yeniIcraDairesiSecimi:IcraDairesiEntity|null = null;
    mounted() {
        this.gecmisIcraDaireleri = IcraDairesiListResponseProvider().data;
        this.gecmisIcraDaireleri.pop();
        this.gecmisIcraDaireleri.pop();
    }
    get tumIcraDaireleri():IcraDairesiEntity[]{
        return this.gecmisIcraDaireleri.concat(
            (this.yeniIcraDairesiSecimi ? [this.yeniIcraDairesiSecimi] : [] )
        ).concat(
            {
                isim: "Ara..", id: 0, il: new IlEntity(), icraVergiNo:"",daireNo:0,ilceMi:false
            }
        )
    }
    daireSecildi() {
        if(this.localValue && this.localValue.id == 0){
            this.dialog = true;
        }else{

            this.input();
        }
    }
    daireSecimSuccess(item:IcraDairesiEntity){
        this.yeniIcraDairesiSecimi = item;
        this.localValue=item;
        this.input();
    }

    daireSecimCancel(){
        this.yeniIcraDairesiSecimi=null;
        this.value=null;
    }

}
