























































































import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import DeleteButton from "@/components/common-inputs/DeleteButton.vue";
import KimlikBilgileriForm from "@/components/comps/forms/KimlikBilgileriForm.vue";
import {AvukatKimlikKartiEntity} from "@/entity/AvukatKimlikKartiEntity";
import {EhliyetEntity} from "@/entity/EhliyetEntity";
import {PasaportEntity} from "@/entity/PasaportEntity";
import {NufusCuzdaniEntity} from "@/entity/NufusCuzdaniEntity";
import {KimlikBelgesiTuru} from "@/enum/KimlikBelgesiTuru";
import KimlikKartiViewCard from "@/components/kisi/kimlik/KimlikKartiViewCard.vue";
import {KisiListResponseProvider} from "@/services/KisiService";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import {KimlikBelgesiEntity} from "@/entity/KimlikBelgesiEntity";

@Component({
    components: {FormDialogButton, KimlikKartiViewCard, KimlikBilgileriForm, DeleteButton}
})
export default class KimlikBilgileriList extends Vue {
    @Prop({})
    editable!: boolean;


    @Prop({})
    item!: Array<KimlikBelgesiEntity>;
    itemCopy!:Array<KimlikBelgesiEntity>;
    mounted(){
        this.itemCopy = Object.assign({}, this.item);
        console.log("kisi:",this.kisi);
        console.log("item:",this.item);
    }

    kisi = KisiListResponseProvider().data[0];
    key!: string;
    nufusCuzdaniCount=0;
    avukatKimlikCount=0;
    ehliyetCount=0;
    pasaportCount=0;
    get varOlanKimlikler(): Array<object> {
        let  arr: object[] = [];
        this.item.forEach((kimlikbelgesi:KimlikBelgesiEntity)=> {
            if (kimlikbelgesi.kimlikBelgesiTuru == KimlikBelgesiTuru.nufusCuzdani) {
                if (this.nufusCuzdaniCount===0){
                    arr.push({
                        title: 'Nüfus Cüzdanı',
                        key: 'nufusCuzdani'
                    });
                    this.nufusCuzdaniCount++;
                }
            }
            if (kimlikbelgesi.kimlikBelgesiTuru == KimlikBelgesiTuru.pasaport) {
                if (this.pasaportCount===0){
                    arr.push({
                        title: 'Pasaport',
                        key: 'pasaport'
                    });
                    this.pasaportCount++;
                }
            }
            if (kimlikbelgesi.kimlikBelgesiTuru == KimlikBelgesiTuru.avukatKimlikKarti) {
                if (this.avukatKimlikCount===0){
                    arr.push({
                        title: 'Avukat Kimlik Kartı',
                        key: 'avukatKimlikKarti'
                    });
                    this.avukatKimlikCount++;
                }
            }
            if (kimlikbelgesi.kimlikBelgesiTuru == KimlikBelgesiTuru.ehliyet) {
                if (this.ehliyetCount===0){
                    arr.push({
                        title: 'Ehliyet',
                        key: 'ehliyet'
                    });
                    this.ehliyetCount++;
                }
            }
        })
        return arr;
    }
    get eksikKimlikler(): Array<object> {
        let arr: object[] = [];
        this.item.forEach((kimlikbelgesi:KimlikBelgesiEntity)=>{
            if (kimlikbelgesi.kimlikBelgesiTuru !== KimlikBelgesiTuru.nufusCuzdani) {
                 let  obj = {
                    title: 'Nüfus Cüzdanı',
                        key: 'nufusCuzdani'
                }
                this.nufusCuzdaniCount === 0 ? arr.push(obj): console.log()
            }
            if (kimlikbelgesi.kimlikBelgesiTuru !== KimlikBelgesiTuru.pasaport) {
                let  obj = {
                    title: 'Pasaport',
                    key: 'pasaport'
                }
                this.pasaportCount === 0 ? arr.push(obj): console.log()
            }
            if (kimlikbelgesi.kimlikBelgesiTuru !== KimlikBelgesiTuru.avukatKimlikKarti && this.avukat) {
                let  obj = {
                    title: 'Avukat Kimlik Kartı',
                    key: 'avukatKimlikKarti'
                }
                this.avukatKimlikCount === 0 ? arr.push(obj): console.log()
            }
            if (kimlikbelgesi.kimlikBelgesiTuru !== KimlikBelgesiTuru.ehliyet) {
                let  obj = {
                    title: 'Ehliyet',
                    key: 'ehliyet'
                }
                this.ehliyetCount === 0 ? arr.push(obj): console.log()
            }
        })
        return arr;
    }

    @Watch('item')
    onValueChange() {
        this.item = this.itemCopy;
    }

    @Prop({
        default: false
    })
    avukat!: boolean;

    initialObjects = {
        "ehliyet":new EhliyetEntity(),
        "avukatKimlikKarti":new AvukatKimlikKartiEntity(),
        "pasaport":new PasaportEntity(),
        "nufusCuzdani":new NufusCuzdaniEntity(),
    }

    itemGetir(belgeTuruParameter:KimlikBelgesiTuru): KimlikBelgesiEntity|undefined
    {
        console.log("Secilen Belge Türü: ",)
       return this.item.find(x=> x.kimlikBelgesiTuru === belgeTuruParameter);
    }


}
