import {KisiKurumEntity} from "@/entity/KisiKurumEntity";
import {Cinsiyet} from "@/enum/Cinsiyet";
import {KisiKurumTuru} from "@/enum/KisiKurumTuru";
import {IlEntity} from "@/entity/IlEntity";
import { KurumEntity } from './KurumEntity';
import {KimlikBelgesiEntity} from "@/entity/KimlikBelgesiEntity";

export class KisiEntity extends KisiKurumEntity {
    ad: string | null = null;
    soyad: string | null = null;
    tc: string | null = null;
    dogumYeri: IlEntity | null = null;
    dogumTarihi: Date | null = null;
    cinsiyet: Cinsiyet = Cinsiyet.bilinmiyor;
    ortakOlduguKurumlar: Array<KurumEntity> = [];
    kisiKurumTuru = KisiKurumTuru.sahis;
    kimlikBelgeleri: Array<KimlikBelgesiEntity> | null=null;
}
