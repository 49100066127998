export enum HesapDonemi {
    sabit = "sabit",
    yillik = "yillik",
    aylik = "aylik",
}

export enum HesapDonemiLabel {
    sabit = "Sabit",
    yillik = "Yıllık",
    aylik = "Aylık",
}
