export enum TebligatSekli {
    normal = "normal",
    yirmibir = "yirmibir",
    otuzbes = "otuzbes",
    ilanen = "ilanen",
    elektronik = "elektronik"
}

export enum TebligatSekliLabel {
    normal = "Normal",
    yirmibir = "21'e göre",
    otuzbes = "35'e göre",
    ilanen = "İlanen",
    elektronik = "Elektronik",
}
