







import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class IptalButton extends Vue {
    @Prop({
            default: "mdi-close"
        }
    )
    icon!: string;
}
