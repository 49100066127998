export enum HesapSekli {
    gun360 = "gun360",
    gun365 = "gun365",
    gun360YAG = "gun360yag",
    gun360uyapyillik = "gun360uyapyillik",
    aylik = "aylik",
}

export enum HesapSekliLabel {
    gun360 = "360 Gün",
    gun365 = "365 Gün",
    gun360YAG = "360 YAG Gün",
    gun360uyapyillik = "360 Gün Uyap Yıllık",
    aylik = "Aylık",
}
