











import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import {IlEntity} from "@/entity/IlEntity";
import {IlListResponseProvider} from "@/services/IlService";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component({})
export default class IlPicker extends Mixins(ObjectInputMixin) {

    @Prop()
    value!: IlEntity | null;

    @Prop()
    label!: string;

    get getLabel(): string{
        if(this.label){
            return this.label
        }
        return "İl Seçiniz"
    }

    items = IlListResponseProvider().data;

    mounted() {
        console.log("gelen il: ", this.value);
    }
}
