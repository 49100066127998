var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items != null)?_c('v-card',{staticClass:"mx-auto"},[_c('v-toolbar',{attrs:{"dense":"","color":"light-blue darken-4","dark":""}},[_c('v-toolbar-title',[_vm._v("Takip Listesi")]),_c('v-spacer'),_c('v-text-field',{attrs:{"hide-details":"","label":"Ara","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"grey"},on:{"click":function($event){_vm.filter=!_vm.filter}}},[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v("mdi-filter")])],1),_c('form-dialog-button',{attrs:{"action":"/api/takip/taslak","title":"Takip Talebi Bilgileri","icon":"mdi-plus","dark":"","color":"green"},model:{value:(_vm.takipTalebiObject),callback:function ($$v) {_vm.takipTalebiObject=$$v},expression:"takipTalebiObject"}},[_c('takip-talebi-form',{model:{value:(_vm.takipTalebiObject),callback:function ($$v) {_vm.takipTalebiObject=$$v},expression:"takipTalebiObject"}})],1)],1),(_vm.filter==true)?_c('v-card-text',{staticStyle:{"background-color":"ghostwhite"}},[_c('kosullar')],1):_vm._e(),_c('v-data-table',{attrs:{"dense":"","no-data-text":"Aradığınız kriterlerde takip bulunamadı","headers":_vm.headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"item.takipTalebi.takipTipi.aciklama",fn:function(ref){
var item = ref.item;
var header = ref.header;
var value = ref.value;
return [_c('span',{on:{"click":function($event){return _vm.detay(item)}}},[_c('kisa-metin',{model:{value:(value),callback:function ($$v) {value=$$v},expression:"value"}})],1)]}},{key:"item.takipTalebi.takipTarihi",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$helper.tarihStr(item.takipTalebi.takipTarihi))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"two-buttons-in-actions-cell"},[_c('v-btn',{attrs:{"color":"light-blue darken-4","icon":""},on:{"click":function($event){return _vm.detay(item)}}},[_c('v-icon',[_vm._v("mdi-arrow-right-bold-box")])],1),_c('delete-button')],1)]}}],null,false,4144686081)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }