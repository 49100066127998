import {ParaBirimiListResponseProvider} from "@/services/ParaBirimiService";
import { ParaBirimiEntity } from '@/entity/ParaBirimiEntity';
import { NafakaEntity } from '@/entity/AlacakBelgeler/NafakaEntity';


class NafakaListResponse {
    data!:NafakaEntity[]
}

export function NafakaListResponseProvider(depth:number=3): NafakaListResponse {
    let paraBirimleriResponse = Array<ParaBirimiEntity>();
    if(--depth > 0)
    {
        paraBirimleriResponse = ParaBirimiListResponseProvider(depth).data;
    }
    return {
        data: [
            {
                id:1,
                baslangicTarihi: new Date("2012-02-12"),
                bitisTarihi: new Date("2019-03-12"),
                tutar:1500,
                paraBirimi:paraBirimleriResponse[0]
            },
            {
                id:2,
                baslangicTarihi: new Date("2012-04-12"),
                bitisTarihi: new Date("2019-05-12"),
                tutar:2000,
                paraBirimi:paraBirimleriResponse[0]
            },
            {
                id:3,
                baslangicTarihi: new Date("2012-04-12"),
                bitisTarihi: new Date("2019-05-12"),
                tutar:1234,
                paraBirimi:paraBirimleriResponse[0]
            }
        ]
    }
}
