var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isTable)?_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","dark":"","color":_vm.toolbarRenk}},[_c('v-card-title',[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),(_vm.isAddable)?_c('form-dialog-button',{attrs:{"dark":"","color":_vm.colorbtn,"icon":"mdi-plus"},on:{"success":_vm.addItem},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_vm._t("form",null,{"formData":formData,"onInput":onInput})]}}],null,true)}):_vm._e(),_vm._t("toolbar-ext")],2),(_vm.value != null && _vm.value.length > 0)?_c('v-card-text',[_c('v-simple-table',[_c('thead',[_vm._l((_vm.headers),function(header,colIndex){return _c('th',[_vm._v(" "+_vm._s(header.text)+" ")])}),_c('th',[_vm._v("İşlemler")])],2),_c('tbody',_vm._l((_vm.value),function(item,index){return _c('tr',[_vm._l((_vm.headers),function(header,colIndex){return _c('td',[(header.renderer)?[_vm._v(" "+_vm._s(header.renderer(item))+" ")]:[_vm._v(" "+_vm._s(_vm.getDescendantProp( item, header.value))+" ")]],2)}),_c('td',{attrs:{"width":_vm.actionsWidth}},[(_vm.isDetail)?_c('form-dialog-button',{attrs:{"medium":true,"icon":"mdi-file"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_vm._t("detayForm",null,{"formData":formData,"onInput":onInput})]}}],null,true),model:{value:(_vm.value[index]),callback:function ($$v) {_vm.$set(_vm.value, index, $$v)},expression:"value[index]"}}):_vm._e(),(_vm.isEditable)?_c('form-dialog-button',{attrs:{"medium":true,"icon":"mdi-pencil"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_vm._t("form",null,{"formData":formData,"onInput":onInput})]}}],null,true),model:{value:(_vm.value[index]),callback:function ($$v) {_vm.$set(_vm.value, index, $$v)},expression:"value[index]"}}):_vm._e(),(_vm.isDeletable)?_c('delete-button',{on:{"success":function($event){return _vm.deleteItem(item)}}}):_vm._e()],1)],2)}),0)])],1):_vm._e()],1):(_vm.isList)?_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","color":_vm.toolbarRenk}},[_c('v-card-title',[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('form-dialog-button',{attrs:{"dark":"","color":"blue","icon":"mdi-plus"},on:{"success":_vm.addItem},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_vm._t("form",null,{"formData":formData,"onInput":onInput})]}}],null,true)})],1),_c('v-card-text',[_c('v-list',_vm._l((_vm.value),function(item,index){return (_vm.value != null && _vm.value.length > 0)?_c('v-list-item',[_c('v-list-item-content',[_vm._l((_vm.headers),function(header,colIndex){return [(colIndex == 0)?_c('v-list-item-title',[(header.renderer)?[_vm._v(" "+_vm._s(header.renderer(item))+" ")]:[_vm._v(" "+_vm._s(_vm.getDescendantProp( item, header.value))+" ")]],2):_vm._e(),(colIndex == 1)?_c('v-list-item-subtitle',[(header.renderer)?[_vm._v(" "+_vm._s(header.renderer(item))+" ")]:[_vm._v(" "+_vm._s(_vm.getDescendantProp( item, header.value))+" ")]],2):_vm._e()]})],2),(_vm.isEditable)?_c('v-list-item-action',[_c('form-dialog-button',{attrs:{"medium":true,"icon":"mdi-pencil"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_vm._t("form",null,{"formData":formData,"onInput":onInput})]}}],null,true),model:{value:(_vm.value[index]),callback:function ($$v) {_vm.$set(_vm.value, index, $$v)},expression:"value[index]"}})],1):_vm._e(),(_vm.isDeletable)?_c('v-list-item-action',[_c('delete-button',{on:{"success":function($event){return _vm.deleteItem(item)}}})],1):_vm._e()],1):_c('v-list-item',[_c('v-list-item-content',[_c('form-dialog-button',{attrs:{"dark":"","color":"green","icon":"mdi-plus","label":"Yeni Kayıt Ekle"},on:{"success":_vm.addItem},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_vm._t("form",null,{"formData":formData,"onInput":onInput})]}}],null,true)})],1)],1)}),1)],1)],1):(_vm.isText)?_c('v-card',[_vm._l((_vm.value),function(item,index){return (_vm.value != null && _vm.value.length > 0)?_c('span',[_c('form-dialog-button',{attrs:{"medium":true,"icon":"mdi-pencil"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_vm._t("form",null,{"formData":formData,"onInput":onInput})]}},{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',[(index>0)?_c('span',[_vm._v(",")]):_vm._e(),_c('span',_vm._g({},on),[_vm._l((_vm.headers),function(header,colIndex){return [(colIndex == 0)?_c('span',[(header.renderer)?[_vm._v(" "+_vm._s(header.renderer(item))+" ")]:[_vm._v(" "+_vm._s(_vm.getDescendantProp( item, header.value))+" ")]],2):_vm._e()]})],2),_c('span',[(_vm.isDeletable)?_c('delete-button',{on:{"success":function($event){return _vm.deleteItem(item)}}}):_vm._e()],1)])]}}],null,true),model:{value:(_vm.value[index]),callback:function ($$v) {_vm.$set(_vm.value, index, $$v)},expression:"value[index]"}})],1):_vm._e()}),_c('form-dialog-button',{attrs:{"icon":"mdi-plus"},on:{"success":_vm.addItem},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_vm._t("form",null,{"formData":formData,"onInput":onInput})]}}],null,true)})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }