import {MuvekkilEntity} from "@/entity/MuvekkilEntity";
import {KisiListResponseProvider} from "@/services/KisiService";
import {VekaletListResponseProvider} from "@/services/VekaletService";
import {KurumListResponseProvider} from "@/services/KurumService";
import { VekaletEntity } from '@/entity/VekaletEntity';
import { KisiEntity } from '@/entity/KisiEntity';
import { KurumEntity } from '@/entity/KurumEntity';

export class MuvekkilListService {
    data!: Array<MuvekkilEntity>;
}

export function MuvekkilListResponseProvider(depth: number = 6, id: number = 0): MuvekkilListService {

    let kisilerResponse = Array<KisiEntity>();
    let kurumlarResponse = Array<KurumEntity>();
    let vekaletResponseArray = Array<Array<VekaletEntity>>();
    if(--depth > 0)
    {
        kisilerResponse = KisiListResponseProvider(depth).data;
        kurumlarResponse = KurumListResponseProvider(depth).data;
        vekaletResponseArray = [
            VekaletListResponseProvider(depth,1).data,
            VekaletListResponseProvider(depth,2).data,
            VekaletListResponseProvider(depth,3).data,
        ];
    }
    switch (id)
    {
        case 1:
        {
            return {
                data: [
                    {
                        id: 1,
                        kisiKurum: kisilerResponse[0],
                        vekalet: vekaletResponseArray[0],
                    },
                    {
                        id: 2,
                        kisiKurum: kisilerResponse[1],
                        vekalet: vekaletResponseArray[0],
                    },
                    {
                        id: 6,
                        kisiKurum: kurumlarResponse[2],
                        vekalet: vekaletResponseArray[0],
                    },
                ]
            }
            break;
        }
        case 2:
        {
            return {
                data: [
                    {
                        id: 3,
                        kisiKurum: kisilerResponse[2],
                        vekalet: vekaletResponseArray[1],
                    },
                    {
                        id: 4,
                        kisiKurum: kurumlarResponse[0],
                        vekalet: vekaletResponseArray[1],
                    },
                    {
                        id: 5,
                        kisiKurum: kurumlarResponse[1],
                        vekalet: vekaletResponseArray[1],
                    },
                ]
            }
            break;
        }
        case 3:
        {
            return {
                data:[
                    {
                        id: 1,
                        kisiKurum: kisilerResponse[0],
                        vekalet: vekaletResponseArray[0],
                    },
                ]
            }
        }
        default:
        {
            return {
                data: [
                    {
                        id: 1,
                        kisiKurum: kisilerResponse[0],
                        vekalet: vekaletResponseArray[2],
                    },
                    {
                        id: 2,
                        kisiKurum: kisilerResponse[1],
                        vekalet: vekaletResponseArray[0],
                    },
                    {
                        id: 6,
                        kisiKurum: kurumlarResponse[2],
                        vekalet: vekaletResponseArray[0],
                    },
                    {
                        id: 3,
                        kisiKurum: kisilerResponse[2],
                        vekalet: vekaletResponseArray[1],
                    },
                    {
                        id: 4,
                        kisiKurum: kurumlarResponse[0],
                        vekalet: vekaletResponseArray[1],
                    },
                    {
                        id: 5,
                        kisiKurum: kurumlarResponse[1],
                        vekalet: vekaletResponseArray[1],
                    },
                ]
            }
            break;
        }
    }
}
