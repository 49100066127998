




























import {Component, Prop, Vue} from "vue-property-decorator";

import DeleteButton from "@/components/common-inputs/DeleteButton.vue";
import {IcraDairesiEntity} from "@/entity/IcraDairesiEntity";
import {IcraDairesiListResponseProvider} from "@/services/IcraDairesiService";


@Component({
    components: {DeleteButton}
})
export default class IcraDairesiList extends Vue {
    IcraDairesiList: IcraDairesiEntity[] = [];
    search: string = "";

    headers = [
        {
            text: "İcra Dairesi",
            align: "start",
            value: "isim"
        },
        {
            text: "İl",
            value: "il.isim"
        },
        {text: "Seç", value: "actions"}


    ];
    @Prop({})
    value!: IcraDairesiEntity;

    mounted() {
        this.IcraDairesiList = IcraDairesiListResponseProvider().data;
    }

    sendDaire(item:IcraDairesiEntity) {
        this.$emit('input',item);
        this.$emit('close');
    }

    close() {
        this.$emit('cancel');
        this.$emit('close');
    }


}

