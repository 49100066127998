var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto"},[_c('v-toolbar',{attrs:{"dense":"","color":"light-blue darken-4","dark":""}},[_c('v-toolbar-title',[_vm._v("Kimlik Bilgileri")]),_c('v-spacer'),_c('v-row',[(_vm.eksikKimlikler.length>0)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-spacer'),_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"green"}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)]}}],null,false,1061698918)},[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.eksikKimlikler),function(kimlik,index){return [_c('form-dialog-button',{attrs:{"title":"Kimlik Bilgileri","initialValue":_vm.initialObjects[kimlik.key],"icon":"mdi-pencil"},on:{"success":function (newItem){ _vm.item[kimlik.key] = newItem }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var localValue = ref.localValue;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-title',[_vm._v(_vm._s(kimlik.title))])],1)]}},{key:"default",fn:function(ref){
var localValue = ref.localValue;
var initialValue = ref.initialValue;
return [_c('kimlik-bilgileri-form',{attrs:{"item":initialValue},model:{value:(localValue),callback:function ($$v) {localValue=$$v},expression:"localValue"}})]}}],null,true)})]})],2)],1):_vm._e()],1)],1),_c('v-list',[_vm._l((_vm.varOlanKimlikler),function(kimlik,index){return [(index>0)?_c('v-divider'):_vm._e(),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(kimlik.title)+" ")])],1),_c('v-list-item-action',[_c('form-dialog-button',{attrs:{"title":"Kimlik Kartı Bilgileri","medium":"","viewOnly":"","icon":"mdi-eye-outline"},model:{value:(kimlik.key),callback:function ($$v) {_vm.$set(kimlik, "key", $$v)},expression:"kimlik.key"}},[_c('kimlik-karti-view-card',{attrs:{"kisi":_vm.kisi,"item":_vm.itemGetir(kimlik.key)}})],1)],1),_c('v-list-item-action',[_c('form-dialog-button',{attrs:{"title":"Kimlik Bilgileri","medium":"","icon":"mdi-pencil"},model:{value:(_vm.item[index]),callback:function ($$v) {_vm.$set(_vm.item, index, $$v)},expression:"item[index]"}},[_c('kimlik-bilgileri-form',{model:{value:(_vm.item[index]),callback:function ($$v) {_vm.$set(_vm.item, index, $$v)},expression:"item[index]"}})],1)],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","color":"cyan darken-4","fab":"","small":""},on:{"click":function($event){return _vm.editKimlikBilgi(_vm.item[ kimlik.key ])}}},[_c('v-icon',[_vm._v("mdi-image")])],1)],1)],1)]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }