import {ParaBirimiListResponseProvider} from "@/services/ParaBirimiService";
import {KisiListResponseProvider} from "@/services/KisiService";
import {FaizListResponseProvider} from "@/services/FaizService";
import {TeminatTuru} from "@/enum/TeminatTuru";
import { ParaBirimiEntity } from '@/entity/ParaBirimiEntity';
import { KisiEntity } from '@/entity/KisiEntity';
import { FaizEntity } from '@/entity/FaizEntity';
import { IlamliAlacakTuru } from '@/entity/IlamliAlacakTuru';
import { IlamliAlacakKalemiEntity } from '@/entity/AlacakBelgeler/IlamliAlacakKalemiEntity';
import { IlamliAlacakEntity } from '@/entity/AlacakBelgeler/IlamliAlacakEntity';
import { IlamliAlacakListResponseProvider } from './IlamliAlacakService';
import { TakipEntity } from '@/entity/TakipEntity';
import { TakipListResponseProvider } from '../TakipService';
import { HesapSekli } from '@/enum/HesapSekli';
import { AlacakKalemiTuru } from '@/enum/AlacakKalemiTuru';
import { IlamliAlacakTuruListResponseProvider } from '@/services/AlacakBelgeleri/IlamliAlacakTuruService';
import { NafakaEntity } from '@/entity/AlacakBelgeler/NafakaEntity';
import { NafakaListResponseProvider } from './NafakaService';
import { IlamliDigerEntity } from '@/entity/AlacakBelgeler/IlamliDigerEntity';
import { IlamliDigerListResponseProvider } from './IlamliDigerService';
import { IlamTuru } from '@/enum/IlamTuru';
import { NafakaTuru } from '@/enum/NafakaTuru';

class IlamliAlacakKalemiListResponse {
    data!: Array<IlamliAlacakKalemiEntity>
}

export function IlamliAlacakKalemiListResponseProvider(depth:number=5): IlamliAlacakKalemiListResponse {
    let alacakKalemTurleriResponse = Array<IlamliAlacakTuru>();
    let paraBirimleriResponse = Array<ParaBirimiEntity>();
    let kisilerResponse = Array<KisiEntity>();
    let faizlerResponse = Array<FaizEntity>();
    let alacaklarResponse = Array<IlamliAlacakEntity>();
    let takiplerResponse = Array<TakipEntity>();
    let nafakalarResponse = Array<NafakaEntity>();
    let digerlerResponse = Array<IlamliDigerEntity>();
    if(--depth > 0)
    {
        alacakKalemTurleriResponse = IlamliAlacakTuruListResponseProvider(depth).data;
        paraBirimleriResponse = ParaBirimiListResponseProvider(depth).data;
        kisilerResponse = KisiListResponseProvider(depth).data;
        faizlerResponse = FaizListResponseProvider(depth).data;
        alacaklarResponse = IlamliAlacakListResponseProvider(depth).data;
        takiplerResponse = TakipListResponseProvider(depth).data;
        nafakalarResponse = NafakaListResponseProvider(depth).data;
        digerlerResponse = IlamliDigerListResponseProvider(depth).data;

    }
    return {
        data: [
            {
                id: 1,
                //ilamTuru:alacakKalemTurleriResponse[0].ilamTuru,
                takip: takiplerResponse[0],
                alacaklar: alacaklarResponse,
                takipOncesiFaiz: faizlerResponse[0],
                takipOncesiHesapSekli: HesapSekli.aylik,
                takipSonrasiFaiz: faizlerResponse[0],
                takipSonrasiHesapSekli: HesapSekli.aylik,
                ilamTuru: IlamTuru.nafaka,
                alacakKalemiTuru: AlacakKalemiTuru.ilamli,
                tutar:8500,
                paraBirimi: paraBirimleriResponse[0],
                vadeTarihi: new Date("2020-01-01"),
                takibeEklenecekTutar:null,
                davaAcilisTarihi:new Date("2020-01-01"),
                kesinlesmeTarihi:new Date("2020-01-01"),
                paraIleOlculenAlacakVarMi:false,
                teminatVarMi:false,
                icraninGeriBirakilmasiVarMi:false,
                borcluyaVerilenMehlilMuddeti:5,
                teminatTuru:TeminatTuru.diger,
                teminatTutari:200,
                teminatAciklamasi:"Teminat Açıklaması",
                icraninGeriBirakilmasiKarariAlindiMi:true,
                yapilmasiYadaYapilmamasiIstenilenIs:"yapılması veya yapılmaması istenilen iş",
                nafakaTuru: NafakaTuru.yoksullukNafakasi,
                belgeAciklamasi:"Açıklama",
                belgeTarihi:new Date("2020-01-01"),
                alacakTutari: 2500,
                yargilamaGideri: 1500,
                icraInkarTazminati: 250,
                mahkemeVekaletUcreti: 1420,
                nafakalar: nafakalarResponse,
                irtifakHakkiAciklama: "irtifakHakkiAciklama - irtifakHakkiAciklama - irtifakHakkiAciklama",
                digerler:digerlerResponse
            },
        ]
    }
}
