import {VekaletEntity} from "@/entity/VekaletEntity";
import {NoterListResponseProvider} from "@/services/NoterService";
import {NotListResponseProvider} from "@/services/NotlarService";
import {MuvekkilListResponseProvider} from "@/services/MuvekkilService";
import {TakipListResponseProvider} from './TakipService';
import { MuvekkilEntity } from '@/entity/MuvekkilEntity';
import { NotEntity } from '@/entity/NotEntity';
import { NoterEntity } from '@/entity/NoterEntity';
import { TakipEntity } from '@/entity/TakipEntity';


export class VekaletListResponse {
    data!: VekaletEntity[];
}

export function VekaletListResponseProvider( depth:number = 3,id: number = 0 ): VekaletListResponse {
    let takiplerResponse = Array<TakipEntity>();
    let notlarResponse = Array<NotEntity>();
    let noterlerResponse = Array<NoterEntity>();
    let muvekkillerArrayResponse = Array<Array<MuvekkilEntity>>();
    if(--depth > 0){
        takiplerResponse = TakipListResponseProvider(depth).data;
        notlarResponse = NotListResponseProvider(depth).data;
        noterlerResponse = NoterListResponseProvider(depth).data;
        muvekkillerArrayResponse = [
            MuvekkilListResponseProvider(depth,1).data,
            MuvekkilListResponseProvider(depth,2).data,
            MuvekkilListResponseProvider(depth,3).data,
        ];
    }
    switch (id) {
        case 1:
        {
            return {
                data: [
                    {
                        id: 1,
                        takip: takiplerResponse[0],
                        ad:"Şengül, Ahmet, Alliance Vekaleti",
                        muvekkiller: muvekkillerArrayResponse[0],
                        noterIslem: {
                            id: 1,
                            noter: noterlerResponse[0],
                            yevmiyeNo: 25,
                            tarih: new Date("2018-02-13"),
                        },
                        notlar: notlarResponse
                    },
                ]
            }
            break;
        }
        case 2:
        {
            return {
                data: [
                    {
                        id: 2,
                        takip: takiplerResponse[0],
                        ad:"Bilge, Gediz, Fenerbahçe Vekaleti",
                        muvekkiller: muvekkillerArrayResponse[1],
                        noterIslem: {
                            id: 1,
                            noter: noterlerResponse[1],
                            yevmiyeNo: 24,
                            tarih: new Date("2020-04-03"),
                        },
                        notlar: notlarResponse
                    }
                ]
            }
            break;
        }
        case 3:
        {
            return {
                data: [
                    {
                        id: 1,
                        takip: takiplerResponse[0],
                        ad:"Şengül, Ahmet, Alliance Vekaleti",
                        muvekkiller: muvekkillerArrayResponse[0],
                        noterIslem: {
                            id: 1,
                            noter: noterlerResponse[0],
                            yevmiyeNo: 25,
                            tarih: new Date("2018-02-13"),
                        },
                        notlar: notlarResponse
                    },
                    {
                        id: 3,
                        takip: takiplerResponse[0],
                        ad:"Şengül Vekaleti",
                        muvekkiller: muvekkillerArrayResponse[2],
                        noterIslem: {
                            id: 1,
                            noter: noterlerResponse[1],
                            yevmiyeNo: 24,
                            tarih: new Date("2020-04-03"),
                        },
                        notlar: notlarResponse
                    }
                ]
            }
            break;
        }
        default:
        {
            return {
                data: [
                    {
                        id: 1,
                        takip: takiplerResponse[0],
                        ad:"Şengül, Ahmet, Alliance Vekaleti",
                        muvekkiller: muvekkillerArrayResponse[0],
                        noterIslem: {
                            id: 1,
                            noter: noterlerResponse[0],
                            yevmiyeNo: 25,
                            tarih: new Date("2018-02-13"),
                        },
                        notlar: notlarResponse
                    },
                    {
                        id: 2,
                        takip: takiplerResponse[0],
                        ad:"Bilge, Gediz, Fenerbahçe Vekaleti",
                        muvekkiller: muvekkillerArrayResponse[1],
                        noterIslem: {
                            id: 1,
                            noter: noterlerResponse[1],
                            yevmiyeNo: 24,
                            tarih: new Date("2020-04-03"),
                        },
                        notlar: notlarResponse
                    },
                    {
                        id: 3,
                        takip: takiplerResponse[0],
                        ad:"Şengül Vekaleti",
                        muvekkiller: muvekkillerArrayResponse[2],
                        noterIslem: {
                            id: 1,
                            noter: noterlerResponse[1],
                            yevmiyeNo: 24,
                            tarih: new Date("2020-04-03"),
                        },
                        notlar: notlarResponse
                    }

                ]
            }
            break;
        }
    }
}
