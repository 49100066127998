
import {AlacakKalemiAlani} from "@/entity/AlacakKalemiAlani";
export interface AlacakKalemAlanListResponse {
    data: Array<AlacakKalemiAlani>
}
export function AlacakKalemAlanlariListResponseProvider(depth:number=3): AlacakKalemAlanListResponse {
    return {
        data: [
            {
                id:1,
                alanAdi:"Asıl Alacak",
            },
            {
                id:2,
                alanAdi:"Takip Öncesi Faiz",
            },
            {
                id:3,
                alanAdi:"Tazminat",
            },
            {
                id:4,
                alanAdi:"Komisyon",
            },
            {
                id:5,
                alanAdi:"Protesto",
            },
            {
                id:6,
                alanAdi:"İhtar Masrafı",
            },
            {
                id:7,
                alanAdi:"BSMV 1",
            },
            {
                id:8,
                alanAdi:"İhtiyati Haciz Masrafı + Vekalet Ücreti",
            },
            {
                id:9,
                alanAdi:"Fon",
            },
            {
                id:10,
                alanAdi:"Ek Alan - 1",
            },
            {
                id:11,
                alanAdi:"Ek Alan - 2",
            },
            {
                id:12,
                alanAdi:"Ek Alan - 3",
            },
            {
                id:13,
                alanAdi:"Cezai Şart",
            },
            {
                id:14,
                alanAdi:"KDV",
            },
            {
                id:15,
                alanAdi:"ÖİV",
            },
            {
                id:16,
                alanAdi:"Takip Toplamı",
            },
            {
                id:17,
                alanAdi:"Vekalet Ücreti",
            },
            {
                id:18,
                alanAdi:"Takip Sonrası Faiz",
            },
            {
                id:19,
                alanAdi:"BSMV - 2",
            },
            {
                id:20,
                alanAdi:"Fon - 2",
            },
            {
                id:21,
                alanAdi:"Harç",
            },
            {
                id:22,
                alanAdi:"Takip Sonrası Masraf",
            },
        ]
    }
}
