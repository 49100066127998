


















import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import {mask} from "vue-the-mask";
@Component({
    directives: {
        mask
    },
})
export default class VergiNo extends Vue{
    @Prop({})
    value!: number;

    @Prop()
    required!: any;

    mask= "##########";
    localValue = this.value ? this.value : null;
    rules= [
        (value:any) => {
            return (this.isRequired() && value=="") ? "Zorunlu."  :true
        },
        (value: any) => {
            return (value && value.length == 10) || "10 karakter olmalıdır.";
        },
    ];

    @Watch('value')
    onValueChange(){
        this.localValue = this.value;
    }

    isRequired():boolean{
        return this.required || this.required == "";
    }

    validate() {
        this.$emit("value", this.localValue);
    }

    input(){
        this.$emit('input',this.localValue);
    }

};
