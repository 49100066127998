import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import {TakipTipiListResponseProvider} from "@/services/TakipTipiService";
import {TakipAltTipiResponseProvider} from "@/services/TakipAltTipiService";
import {TakipYoluResponseProvider} from "@/services/TakipYoluService";
import {IlListResponseProvider} from "@/services/IlService";
import {IcraDairesiListResponseProvider} from "@/services/IcraDairesiService";
import { TakipTipiEntity } from '@/entity/TakipTipiEntity';
import { TakipAltTipiEntity } from '@/entity/TakipAltTipiEntity';
import { TakipYoluEntity } from '@/entity/TakipYoluEntity';
import { IcraDairesiEntity } from '@/entity/IcraDairesiEntity';
import { IlEntity } from '@/entity/IlEntity';


export class TakipTalebiListResponse {
    data!: TakipTalebiEntity[];
}

export function TakipTalebiListResponseProvider(depth:number=3): TakipTalebiListResponse {
    let takipTipleriResponse = Array<TakipTipiEntity>();
    let takipAltTipleriResponse = Array<TakipAltTipiEntity>();
    let takipYollariResponse = Array<TakipYoluEntity>();
    let icraDaireleri = Array<IcraDairesiEntity>();
    let iller = Array<IlEntity>();
    if(--depth > 0){
        takipTipleriResponse = TakipTipiListResponseProvider(depth).data;
        takipAltTipleriResponse = TakipAltTipiResponseProvider(depth).data;
        takipYollariResponse = TakipYoluResponseProvider(depth).data;
        icraDaireleri = IcraDairesiListResponseProvider(depth).data;
        iller = IlListResponseProvider(depth).data;
    }
    return {
        data: [
            {
                kartNo: 1,
                takipTipi: takipTipleriResponse[4], //4 te ilamli takip bulunmakta
                takipAltTipi: takipAltTipleriResponse[0],
                takipYolu: takipYollariResponse[0],
                dosyaEsasNo: "2010/001",
                icraDairesi: icraDaireleri[0],
                il: iller[0],
                takipTarihi: new Date("2019-09-12"),
                hesapTarihi: new Date("2019-09-13"),
                ilamTuru: null,
                takipDurumu:"Hitam",
                ekAlan1:'YO-124914',
                ekAlan2:'12312',
                ekAlan3:'2190738',
                ekAlan4:null,
                ekAlan5:null
            },
            {
                kartNo: 2,
                takipTipi: takipTipleriResponse[0], //4 te ilamli takip bulunmakta
                takipAltTipi: takipAltTipleriResponse[0],
                takipYolu: takipYollariResponse[0],
                dosyaEsasNo: "2010/001",
                icraDairesi: icraDaireleri[0],
                il: iller[0],
                takipTarihi: new Date("2019-09-12"),
                hesapTarihi: new Date("2019-09-13"),
                ilamTuru: null,
                takipDurumu:"Derdest",
                ekAlan1:'BA-120948',
                ekAlan2:'12312',
                ekAlan3:'129783',
                ekAlan4:null,
                ekAlan5:null
            }
        ]
    }
}
