import { IlamliAlacakEntity } from '@/entity/AlacakBelgeler/IlamliAlacakEntity';
import { IsciAlacagiEntity } from '@/entity/AlacakBelgeler/IsciAlacagiEntity';
import { BorcluEntity } from '@/entity/BorcluEntity';
import { ParaBirimiEntity } from '@/entity/ParaBirimiEntity';
import { BorcluListResponseProvider } from '../BorcluService';
import { ParaBirimiListResponseProvider } from '../ParaBirimiService';


class IlamliAlacakListResponse {
    data!: Array<IlamliAlacakEntity>
}

export function IlamliAlacakListResponseProvider(depth:number=3): IlamliAlacakListResponse {

    let borclular = Array<BorcluEntity>();
    let paraBirimleriResponse = Array<ParaBirimiEntity>();
    if(--depth > 0)
    {
        borclular = BorcluListResponseProvider(depth).data;
        paraBirimleriResponse = ParaBirimiListResponseProvider(depth).data;
    }
        
    return {
        data: [
            
            <IsciAlacagiEntity>
            {
                id: 1,
                borclu:borclular[0],
                tutar: 500,
                paraBirimi: paraBirimleriResponse[0],
                faizBaslangicTarihi:new Date("2010-10-02")     
            }
        ]
    }
}
