import { MahkemeDosyaTurEntity } from '@/entity/MahkemeDosyaTurEntity';

export class MahkemeDosyaTurListResponse {
    data!: MahkemeDosyaTurEntity[];
}

export function MahkemeDosyaTurListResponseProvider(depth:number=3): MahkemeDosyaTurListResponse {
    return {
        data: [
            {
                id: 3,
                name: "Ceza Dava Dosyası",
            },
            {
                id: 15,
                name: "Hukuk Dava Dosyası",
            }            
        ]
    }
}
