import {HarcOraniEntity} from "@/entity/HarcOraniEntity";

export interface HarcOraniResponse {
    data: HarcOraniEntity[]
}


export function HarcOraniResponseProvider(depth:number=3): HarcOraniResponse {
    return {
        data: [
            {
                id: 1,
                value: 4.55,
            },
            {
                id: 2,
                value: 9.10,
            },
            {
                id: 3,
                value: 11.38,
            },
            {
                id: 4,
                value: 2.27,
            },
            {
                id: 5,
                value: 7.92,
            },
        ]
    }
}