import {FsFileEntity} from "@/entity/FsFileEntity";
import {KimlikBelgesiTuru} from "@/enum/KimlikBelgesiTuru";
import {AvukatKimlikKartiEntity} from "@/entity/AvukatKimlikKartiEntity";
import {EhliyetEntity} from "@/entity/EhliyetEntity";
import {PasaportEntity} from "@/entity/PasaportEntity";
import {NufusCuzdaniEntity} from "@/entity/NufusCuzdaniEntity";
import {IlEntity} from "@/entity/IlEntity";
import { IlceEntity } from './IlceEntity';

export type KimlikBelgesi = AvukatKimlikKartiEntity | EhliyetEntity | PasaportEntity | NufusCuzdaniEntity;

export abstract class KimlikBelgesiEntity{
    id: number | null = null;
    aciklamaVeNotlar: string | null = null;
    dosyalar: Array<FsFileEntity> = [];
    ilce: IlceEntity | null = null;
    kimlikBelgesiTuru: KimlikBelgesiTuru | null = null;
}



