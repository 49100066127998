

import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class AramaGecmisi extends Vue{
    mounted(){
        console.log('ARAMA GEÇMİŞİ MOUNT OLDU');
    }

    items=[]

    statusText:string="Test string";

    @Prop({})
    filters!:Array<any>

    getButtonData = () => {
        return {
            style: { background: 'lightsteelblue' },
            on: { click: () => {console.log('btn clicked')} }
        }
    };

    render(ce:any){

        let buttons = this.items.map(item => ce('button', this.getButtonData(), item));
        let filters = this.filters.map( item => ce('div',item));
        return ce('div', [...buttons,...filters, ce('p',this.statusText)]);
    }

}
