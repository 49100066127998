
























































import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import {AdresEntity} from "@/entity/AdresEntity";
import DeleteButton from "@/components/common-inputs/DeleteButton.vue";
import AdresForm from "@/components/comps/forms/AdresForm.vue";
import AdresListLine from "@/components/comps/lists/AdresListLine.vue";
import {IletisimBilgisiEntity} from "@/entity/IletisimBilgisiEntity";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {mixins} from "vue-class-component";
import {AdresTuruLabel} from "@/enum/AdresTuru";

@Component({
    components: {FormDialogButton, AdresListLine, AdresForm, DeleteButton}
})
export default class AdresList extends Mixins(ObjectInputMixin) {

    AdresTuruLabel = AdresTuruLabel;

    localValue!:{};

    @Prop() items!:AdresEntity[];

    @Prop({})
    editable!: boolean;

    @Prop({default: true})
    addable!: boolean;

}

