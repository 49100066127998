import {IlamliAlacakTuru} from "@/entity/IlamliAlacakTuru";
import {IlamTuru} from "@/enum/IlamTuru";
export interface IlamliAlacakTuruListResponse {
    data: Array<IlamliAlacakTuru>
}
export function IlamliAlacakTuruListResponseProvider(depth:number=4): IlamliAlacakTuruListResponse {
    return {
        data: [
            {
                kod: 1,
                tur: "Asıl Alacak",
                ilamTuru: IlamTuru.genel
            },
            {
                kod: 2,
                tur: "Cezai Şart",
                ilamTuru: IlamTuru.genel
            },
            {
                kod: 3,
                tur: "Tazminat",
                ilamTuru: IlamTuru.genel
            },
            {
                kod: 4,
                tur: "Maddi Tazminat",
                ilamTuru: IlamTuru.genel
            },
            {
                kod: 5,
                tur: "Masnevi Tazminat",
                ilamTuru: IlamTuru.genel
            },
            {
                kod: 6,
                tur: "Destekten Yoksun Kalma Tazminatı",
                ilamTuru: IlamTuru.genel
            },
            {
                kod: 7,
                tur: "İş Görmezlik Tazminatı",
                ilamTuru: IlamTuru.genel
            },
            {
                kod: 8,
                tur: "İş Gücü Kaybı Tazminatı",
                ilamTuru: IlamTuru.genel
            },
            {
                kod: 9,
                tur: "İcra İnkar Tazminatı",
                ilamTuru: IlamTuru.genel
            },
            {
                kod: 10,
                tur: "Yargılama Gideri",
                ilamTuru: IlamTuru.genel
            },
            {
                kod: 11,
                tur: "Mahkeme Vekalet Ücreti",
                ilamTuru: IlamTuru.genel
            },
            {
                kod: 12,
                tur: "Diğer",
                ilamTuru: IlamTuru.genel
            },
            {
                kod: 13,
                tur: "Yapılması Ya Da Yapılmaması İstenen İş",
                ilamTuru: IlamTuru.birIsinYapilmasiyadaYapilmamasi
            },
            {
                kod: 14,
                tur: "Yargılama Gideri",
                ilamTuru: IlamTuru.birIsinYapilmasiyadaYapilmamasi
            },
            {
                kod: 15,
                tur: "Mahkeme Vekalet Ücreti",
                ilamTuru: IlamTuru.birIsinYapilmasiyadaYapilmamasi
            },
            {
                kod: 16,
                tur: "Diğer",
                ilamTuru: IlamTuru.birIsinYapilmasiyadaYapilmamasi
            },
            {
                kod: 17,
                tur: "Kaldırılması İstenen İrtifak Hakkı",
                ilamTuru: IlamTuru.irtifakHakki
            },
            {
                kod: 18,
                tur: "Kaldırılması İstenen Gemi İntifası Hakkı",
                ilamTuru: IlamTuru.irtifakHakki
            },
            {
                kod: 19,
                tur: "Yargılama Gideri",
                ilamTuru: IlamTuru.irtifakHakki
            },
            {
                kod: 20,
                tur: "Mahkeme Vekalet Ücreti",
                ilamTuru: IlamTuru.irtifakHakki
            },
            {
                kod: 21,
                tur: "Kıdem Tazminatı",
                ilamTuru: IlamTuru.isciAlacagi
            },
            {
                kod: 22,
                tur: "İhbar Tazminatı",
                ilamTuru: IlamTuru.isciAlacagi
            },
            {
                kod: 23,
                tur: "Ücret Alacağı",
                ilamTuru: IlamTuru.isciAlacagi
            },
            {
                kod: 24,
                tur: "Fazla Çalışma Alacağı",
                ilamTuru: IlamTuru.isciAlacagi
            },
            {
                kod: 25,
                tur: "Yıllık İzin Ücreti",
                ilamTuru: IlamTuru.isciAlacagi
            },
            {
                kod: 26,
                tur: "Hafta Tatili Ücreti",
                ilamTuru: IlamTuru.isciAlacagi
            },
            {
                kod: 27,
                tur: "Ulusal Bayram ve Genel Tatil Alacağı",
                ilamTuru: IlamTuru.isciAlacagi
            },
            {
                kod: 28,
                tur: "İkramiye ve Prim Alacağı",
                ilamTuru: IlamTuru.isciAlacagi
            },
            {
                kod: 29,
                tur: "Döner Sermaye Alacağı",
                ilamTuru: IlamTuru.isciAlacagi
            },
            {
                kod: 30,
                tur: "Sendikal Tazminat",
                ilamTuru: IlamTuru.isciAlacagi
            },
            {
                kod: 31,
                tur: "Kötü Niyet Tazminatı",
                ilamTuru: IlamTuru.isciAlacagi
            },
            {
                kod: 32,
                tur: "Maddi Tazminat",
                ilamTuru: IlamTuru.isciAlacagi
            },
            {
                kod: 33,
                tur: "Manevi Tazminat",
                ilamTuru: IlamTuru.isciAlacagi
            },
            {
                kod: 34,
                tur: "Destekten Yoksun Kalma Tamzinatı",
                ilamTuru: IlamTuru.isciAlacagi
            },
            {
                kod: 35,
                tur: "Geçici İşgörmezlik Tazminatı",
                ilamTuru: IlamTuru.isciAlacagi
            },
            {
                kod: 36,
                tur: "Sürekli İşgörmezlik Tazminatı",
                ilamTuru: IlamTuru.isciAlacagi
            },
            {
                kod: 37,
                tur: "Tedavi Gideri",
                ilamTuru: IlamTuru.isciAlacagi
            },
            {
                kod: 38,
                tur: "Yargılama Gideri",
                ilamTuru: IlamTuru.isciAlacagi
            },
            {
                kod: 39,
                tur: "Mahkeme Vekalet Ücreti",
                ilamTuru: IlamTuru.isciAlacagi
            },
            {
                kod: 40,
                tur: "Diğer",
                ilamTuru: IlamTuru.isciAlacagi
            },
            {
                kod: 41,
                tur: "Burası Bakım Aşamasında",
                ilamTuru: IlamTuru.limitliAlacak
            },
            {
                kod: 42,
                tur: "İştirak Nafakası",
                ilamTuru: IlamTuru.nafaka
            },
            {
                kod: 43,
                tur: "Yoksulluk Nafakası",
                ilamTuru: IlamTuru.nafaka
            },
        ]
    }
}
