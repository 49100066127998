import {NoterEntity} from "@/entity/NoterEntity";
import {IlListResponseProvider} from "@/services/IlService";
import { IlEntity } from '@/entity/IlEntity';


export class NoterListResponse {
    data!: NoterEntity[];
}

export function NoterListResponseProvider(depth:number=3): NoterListResponse {
    let illerResponse = Array<IlEntity>();
    if(--depth > 0)
    {
        illerResponse = IlListResponseProvider(depth).data;
    }
    return {
        data: [
            {
                id: 1,
                ad: 'izmir 1. noter',
                il: illerResponse[0]
            },
            {
                id: 2,
                ad: 'izmir 2. noter',
                il: illerResponse[0]
            },
            {
                id: 3,
                ad: 'İstanbul 1. noter',
                il: illerResponse[2]
            },
            {
                id: 4,
                ad: 'İstanbul 2. noter',
                il: illerResponse[2]
            },
            {
                id: 5,
                ad: 'İstanbul 3. noter',
                il: illerResponse[2]
            },
            {
                id: 6,
                ad: 'Ankara 1. noter',
                il: illerResponse[1]
            },
            {
                id: 7,
                ad: 'Ankara 2. noter',
                il: illerResponse[1]
            }
        ]
    }
}
