import {CekEntity} from "@/entity/AlacakBelgeler/CekEntity";
import {HesapSekli} from "@/enum/HesapSekli";
import {FaizListResponseProvider} from "@/services/FaizService";
import {ParaBirimiListResponseProvider} from "@/services/ParaBirimiService";
import {ProtestoListResponseProvider} from "@/services/AlacakBelgeleri/ProtestoService";
import {IlamsizBelgeTipi} from "@/enum/IlamsizBelgeTipi";
import {AlacakKalemiTuru} from "@/enum/AlacakKalemiTuru";
import { TakipListResponseProvider } from '../TakipService';
import { ProtestoEntity } from '@/entity/AlacakBelgeler/ProtestoEntity';
import { FaizEntity } from '@/entity/FaizEntity';
import { TakipEntity } from '@/entity/TakipEntity';
import { ParaBirimiEntity } from '@/entity/ParaBirimiEntity';
import { BankaHesapListResponseProvider } from '../BankaHesapService';
import { IhtarListResponseProvider } from './IhtarService';
import { IhtarEntity } from '@/entity/AlacakBelgeler/IhtarEntity';
import { HesapNumarasiEntity } from '@/entity/HesapNumarasiEntity';


class CekListResponse {
    data!: CekEntity[]
}

export function CekListResponseProvider(depth:number=3): CekListResponse {
    let takiplerResponse = Array<TakipEntity>();
    let faizlerResponse = Array<FaizEntity>();
    let paraBirimleriResponse = Array<ParaBirimiEntity>();
    let protestolarResponse = Array<ProtestoEntity>();
    let ihtarlarResponse = Array<IhtarEntity>();
    let bankalarResponse = Array<HesapNumarasiEntity>();
    if(--depth > 0)
    {
        takiplerResponse = TakipListResponseProvider(depth).data;
        faizlerResponse = FaizListResponseProvider(depth).data;
        paraBirimleriResponse = ParaBirimiListResponseProvider(depth).data;
        protestolarResponse = ProtestoListResponseProvider(depth).data;
        ihtarlarResponse = IhtarListResponseProvider(depth).data;
        bankalarResponse = BankaHesapListResponseProvider(depth).data;
    }
    return {
        data:
        [
            {
                alacakKalemiTuru:AlacakKalemiTuru.ilamsiz,
                id: 1,
                takip: takiplerResponse[0],
                aciklama: "Bu bir Çektir",
                cekSeriNo: 123124123,
                cekKesideTarihi: new Date("2010-10-02"),
                cekIbrazTarihi: new Date("2010-10-02"),
                cekKesideYeri: "İzmir",
                cekOdemeYeri: "Ankara",
                tutar: 15000,
                belgeTarihi: new Date("2010-10-02"),
                takipOncesiFaiz: faizlerResponse[0],
                takipOncesiHesapSekli: HesapSekli.aylik,
                takipSonrasiFaiz: faizlerResponse[0],
                takipSonrasiHesapSekli: HesapSekli.aylik,
                paraBirimi: paraBirimleriResponse[0],
                belgeTipi: IlamsizBelgeTipi.cek,
                takibeEklenecekTutar:5000,
                vadeTarihi:new Date("2006-01-05"),
                islemBaslasinMi:true,
                ihtar:ihtarlarResponse[0],
                bankaBilgileri:bankalarResponse[0]
                
            },
            {
                alacakKalemiTuru:AlacakKalemiTuru.ilamsiz,
                id: 1,
                takip: takiplerResponse[0],
                aciklama: "Bu bir Çektir",
                cekSeriNo: 123124123,
                cekKesideTarihi: new Date("2010-10-02"),
                cekIbrazTarihi: new Date("2010-10-02"),
                cekKesideYeri: "İzmir",
                cekOdemeYeri: "Ankara",
                tutar: 60000,
                belgeTarihi: new Date("2010-10-02"),
                takipOncesiFaiz: faizlerResponse[0],
                takipOncesiHesapSekli: HesapSekli.aylik,
                takipSonrasiFaiz: faizlerResponse[0],
                takipSonrasiHesapSekli: HesapSekli.aylik,
                paraBirimi: paraBirimleriResponse[0],
                belgeTipi: IlamsizBelgeTipi.cek,
                takibeEklenecekTutar:20500,
                vadeTarihi:new Date("2006-01-05"),
                islemBaslasinMi:true,
                ihtar:ihtarlarResponse[0],
                bankaBilgileri:bankalarResponse[0]
            }
        ]
    }
}
