








import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import QueryPicker from "@/components/common-inputs/QueryPicker.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {mixins} from "vue-class-component";
import {BankaHesapListResponseProvider} from "@/services/BankaHesapService";
import {BankaListResponseProvider} from "@/services/BankaService";

@Component({
    components: {QueryPicker}
})
export default class BankaPicker extends Mixins(ObjectInputMixin) {
    items= BankaListResponseProvider().data;

}
