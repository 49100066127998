












import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import {mask} from "vue-the-mask";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import {ParaBirimiListResponseProvider} from "@/services/ParaBirimiService";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component({
    directives: {
        mask
    },
})
export default class ParaBirimi extends Mixins(ObjectInputMixin) {

    items = ParaBirimiListResponseProvider().data;

    @Prop() color!: string;

    private localColor = this.color ? this.color : ''
}
