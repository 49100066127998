import {TarafSifatiEntity} from "@/entity/TarafSifatiEntity";

export interface TarafSifatiListResponse {
    data: Array<TarafSifatiEntity>
}

export function TarafSifatiListResponseProvider(depth: number = 1): TarafSifatiListResponse {
    return {
        data: [
            {
                id: 1,
                kod:21,
                tur:"Aracı Kişi Kurum",
            },
            {
                id: 2,
                kod:22,
                tur:"Borçlu",
            },
            {
                id: 3,
                kod:50,
                tur:"Hissedar",
            },
            {
                id: 4,
                kod:23,
                tur:"İflas İdare Memuru",
            },
            {
                id: 5,
                kod:24,
                tur:"İhale Katılımcısı",
            },
            {
                id: 6,
                kod:25,
                tur:"İpotek Sahibi",
            },
            {
                id: 7,
                kod:26,
                tur:"İstihkak İddiası Sahibi",
            },
            {
                id: 8,
                kod:27,
                tur:"İşgalci",
            },
            {
                id: 9,
                kod:28,
                tur:"Kefil",
            },
            {
                id: 10,
                kod:29,
                tur:"Kiracı",
            },
            {
                id: 11,
                kod:31,
                tur:"Müflis",
            },
            {
                id: 12,
                kod:31,
                tur:"Rehin Sahibi",
            },
            {
                id: 13,
                kod:32,
                tur:"Tereke Sorumlusu",
            },
            {
                id: 14,
                kod:33,
                tur:"Üçüncü Şahıs",
            },
            {
                id: 15,
                kod:34,
                tur:"Yed-i Emin Kişi",
            },
            {
                id: 16,
                kod:35,
                tur:"İcra Kefili",
            },
            {
                id: 17,
                kod:36,
                tur:"Kayyum",
            },
            {
                id: 18,
                kod:37,
                tur:"Muris",
            },
            {
                id: 19,
                kod:38,
                tur:"Mirasçı",
            },
            {
                id: 20,
                kod:39,
                tur:"Konkordato Komiseri",
            },
            {
                id: 21,
                kod:40,
                tur:"Temsilci",
            },
            {
                id: 22,
                kod:41,
                tur:"Tedbir Koyduran",
            },
            {
                id: 23,
                kod:42,
                tur:"Haciz Sahibi",
            },
            {
                id: 24,
                kod:43,
                tur:"Mükellefiyet Sahibi",
            },
            {
                id: 25,
                kod:44,
                tur:"Mümessil",
            },
            {
                id: 26,
                kod:45,
                tur:"Diğer Haciz/Rehin Borçlusu",
            },
            {
                id: 27,
                kod:46,
                tur:"Tasfiye Memuru",
            },
            {
                id: 28,
                kod:47,
                tur:"Vasi",
            },
        ]
    }
}